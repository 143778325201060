/* eslint-disable no-unused-vars */
import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

import Vue from 'vue';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import VueTheMask from 'vue-the-mask';
import Axios from 'axios';
import Vue2Filters from 'vue2-filters';
import vuetify from './plugins/vuetify';
import i18n from './locale'; // Internationalization

import App from './App.vue';
import router from './router';
import store from './store';

import './router/permission';
import './registerServiceWorker';

/* Deixando o axios disponível de forma global somente para componentes
através de herança(prototype do javascript). Use this.$axios. */
Vue.prototype.$axios = Axios;

Vue.use(require('vue-moment'));

Vue.use(Vue2Filters);

// Vue.use(VueKeycloakJs, {
//  init: {
//    // Use 'login-required' to always require authentication
//    // If using 'login-required', there is no need for the router guards in router.js
//    onLoad: 'check-sso',
//  },
//  config: {
//    url: process.env.VUE_APP_KEYCLOAK_URL,
//    realm: process.env.VUE_APP_KEYCLOAK_REALM,
//    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
//    logoutRedirectUri: `${process.env.VUE_APP_URL}/sysaga`,
//  },
//  // eslint-disable-next-line no-shadow
//  onReady: (keycloak) => {
//    new Vue({
//      router,
//      store,
//      i18n,
//      vuetify,
//      VueTheMask,
//      render: (h) => h(App),
//    }).$mount('#app');
//  },
// });
new Vue({
  router,
  store,
  i18n,
  vuetify,
  VueTheMask,
  render: (h) => h(App),
}).$mount('#app');
