<template>
  <v-app-bar
    app
    dark
    hide-on-scroll
    :height="toolbarDense ? '121' : '60'"
    class="primary"
  >
    <v-row
      no-gutters
      align="baseline"
      justify="center"
    >
      <!-- Inicio Barra DECEA  -->
      <v-col
        v-if="toolbarDense"
        cols="12"
      >
        <app-toolbar-decea-content />
      </v-col>
      <!-- Fim Barra DECEA  -->
      <!-- Inicio Barra SYSAGA  -->
      <v-col cols="12">
        <v-row
          align="center"
          justify="space-around"
          style="flex-wrap: nowrap;"
        >
          <v-app-bar-nav-icon
            id="hamburger-container"
            @click.stop="toggleNavbar"
          >
            <v-icon>{{ toggleNavbarIcon }}</v-icon>
          </v-app-bar-nav-icon>
          <breadcrumbs />
          <v-spacer />
          <img
            class="hidden-xs-only"
            :src="logoSysaga"
            alt="Logo SYSAGA"
            height="30"
            width="150"
          >
          <v-spacer class="hidden-xs-only" />
          <error-log
            v-if="this.$store.getters.roles.some(e => e === 'Desenvolvedor')"
            id="logs-container"
          />
          <full-screen />
          <notification />
          <localization />
          <profile />
        </v-row>
      </v-col>
      <!-- Fim Barra SYSAGA  -->
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumbs from '../../common/widget/AppBreadcrumbs.vue';
import ErrorLog from '../widget/AppErrorLog.vue';
import FullScreen from '../../common/widget/AppFullScreen.vue';
import Localization from '../../common/widget/AppLocalization.vue';
import Notification from '../../common/widget/AppNotification.vue';
import Profile from '../../common/widget/AppProfile.vue';
import AppToolbarDeceaContent from '../../common/layout/AppToolbarDeceaContent.vue';

export default {
  name: 'TheLayoutSysagaToolbar',
  components: {
    Breadcrumbs,
    ErrorLog,
    FullScreen,
    Localization,
    Notification,
    Profile,
    AppToolbarDeceaContent,
  },
  data: () => ({
    logoSysaga: `${process.env.VUE_APP_URL}/img/logos/logo-sysaga.png`,
  }),
  computed: {
    ...mapGetters([
      'toolbarDense',
      'navbarShow',
    ]),
    toggleNavbarIcon() {
      return this.navbarShow ? 'mdi-format-indent-decrease' : 'mdi-format-indent-increase';
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch('NavbarToggle');
    },
  },
};
</script>
