export default {
  subtitulo:
  `
  Documento elaborado por um Responsável Técnico habilitado e apresentado
  pelo operador do aeródromo, que altera a configuração da infraestrutura
  aeroportuária incluindo as características físicas e/ou operacionais dos
  aeródromos ou helipontos.
  `,
  texto:
  `
  Os aeródromos inscritos no cadastro da ANAC que não submeteram seus Planos
  de Zona de Proteção ao DECEA deverão utilizar este processo para
  regularização junto ao COMAER, conforme previsto no Art. 129 da
  Portaria 957/GC3 de 2015.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
