export default {
  subtitulo:
  `
  Document prepared by a qualified Technical Responsible and
  presented by the aerodrome operator, which changes the configuration
  of the airport infrastructure including the physical and / or operational
  characteristics of the aerodromes or heliports.
  `,
  texto:
  `
  Aerodromes registered in the ANAC register that did not submit
  their Protection Zone Plans to DECEA must use this process for regularization
  with COMAER, as provided for in Article 129 of Ordinance 957 / GC3 of 2015.
  `,
  cardDocumentacao: {
    titulo: 'Related Documentation',
    texto: '',
  },
};
