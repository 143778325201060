export default {
  bem_vindo: 'Bienvenido al Portal AGA',
  abrir_processo: 'PROCESO ABIERTO',
  consultar_planos: 'CONSULTAR PLANES',
  fechar: 'Cerca',
  planos_projetos: 'Planos e Projetos',
  texto: `El portal AGA proporciona información sobre los planes de zonas de protección de aeródromos (PZPA),
            consulta de planes publicados, legislación de referencia y también,
            da acceso a archivos que ayudan en la preparación de procesos.
            El Portal AGA proporciona acceso a SYSAGA,
            Sistema de preparación y consultoría de procesos.`,
  cardPlanos: {
    titulo: 'Planes de Zona de Protección de Aeródromo',
    texto: 'Consulte y aprenda más sobre los planes.',
  },
  cardAnalise: {
    titulo: 'Análisis de OPEA o Proyectos de Aeródromo',
    texto: '¿Qué tipo de proyecto tienes?',
  },
  cardSysaga: {
    titulo: '¿Necesita presentar un caso a través de SYSAGA?',
    texto: 'Inicie sesión o cree una cuenta en el sistema.',
  },
};
