<template>
  <v-container fluid>
    <v-row
      align="center"
      no-gutters
    >
      <v-col>
        <div class="flex-nowrap d-flex justify-start align-center">
          <div class="ml-8">
            <a
              target="_blank"
              href="https://www.decea.gov.br/"
            >
              <v-img
                src="/img/logos/decea_logo_header.png"
                height="43"
              />
            </a>
          </div>

          <div class="hidden-xs-only ml-6 toolbar-search-form">
            <form
              action="https://www.decea.gov.br/?i=busca"
              target="_blank"
              method="get"
            >
              <v-text-field
                placeholder="Busca no decea.gov.br"
                type="search"
                outlined
                rounded
                dense
                light
                solo
                flat
                append-icon="mdi-magnify"
              />
            </form>
          </div>
          <div class="hidden-xs-only ml-4">
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <a
                  target="_blank"
                  href="http://ajuda.decea.gov.br/"
                >                      <v-btn
                  icon
                  dense
                  v-bind="attrs"
                  v-on="on"
                ><v-icon size="24px">mdi-help-circle-outline</v-icon>
                </v-btn>
                </a>
              </template>
              <span>{{ $t('button.helpCenter') }}</span>
            </v-tooltip>
          </div>
          <div class="hidden-xs-only">
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <a
                  target="_blank"
                  href="https://servicos.decea.gov.br/sac"
                >
                  <v-btn
                    icon
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon size="30px">mdi-forum</v-icon>
                  </v-btn>
                </a>
              </template>
              <span>{{ $t('button.contactUs') }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>

      <v-col class="hidden-xs-only flex-grow-0 flex-shrink-0">
        <div align="center">
          <a
            target="_blank"
            href="https://www.fab.mil.br/"
          >
            <v-img
              src="/img/logos/logotipo_fab.png"
              height="31"
              width="231"
            />
          </a>
        </div>
      </v-col>
      <v-col class="hidden-sm-and-up">
        <div align="center">
          <a
            target="_blank"
            href="https://www.fab.mil.br/"
          >
            <v-img
              src="/img/logos/logo-fab-vertical-branco-96x96.png"
              height="50"
              width="50"
            />
          </a>
        </div>
      </v-col>
      <v-col class="hidden-sm-and-down">
        <div class="flex-nowrap d-flex justify-end mr-8">
          <div>
            <a
              target="_blank"
              href="https://www.brasil.gov.br"
            >
              <v-btn
                x-small
                color="transparent"
                style="box-shadow: none"
              >
                <img
                  src="/img/logos/logo_govbr.png"
                  alt="Brasil"
                >
              </v-btn>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://brasil.gov.br/barra#participe"
            >
              <v-btn
                x-small
                dark
                color="transparent"
                style="box-shadow: none"
              >
                Participe
              </v-btn>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://brasil.gov.br/barra#acesso-informacao"
            >
              <v-btn
                x-small
                dark
                color="transparent"
                style="box-shadow: none"
              >
                Acesso à informação</v-btn>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.planalto.gov.br/legislacao"
            >
              <v-btn
                x-small
                dark
                color="transparent"
                style="box-shadow: none"
              >Legislação</v-btn>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://brasil.gov.br/barra#orgaos-atuacao-canais"
            >
              <v-btn
                x-small
                dark
                color="transparent"
                style="box-shadow: none"
              >Canais</v-btn>
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AppToolbarDeceaContent',
  data: () => ({}),
};
</script>

<style lang="scss">
  .toolbar {
    &-search-form {
      .v-input {
        .v-input__slot {
          background-color: #fff;
          padding: 0 12px;
        }
        .v-input__icon {
          .v-icon {
            color: #606060;
          }
        }
        .v-input__slot {
          margin: 0;
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
  }
</style>
