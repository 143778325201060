<template>
  <v-main class="layout__content-background-color background_imagem_aerovias">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
    >
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
  </v-main>
</template>

<script>
export default {
  name: 'TheLayoutPortalContent',
  data: () => ({}),
};
</script>
<style scoped>
.background_imagem_aerovias {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/*
Udemy Aula 176 do Plínio Naves
Para usar essas classes insira name="fade" na tag transition e remova enter-active-class.

Udemy Aula 183 do Plínio Naves
Mostra o uso de animações de terceiros(Animate.css que foi importada em App.vue)
https://animate.style/
*/
/* .fade-enter, .fade-leave-to {
  opacity: 0.9;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
} */
</style>
