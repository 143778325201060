export default {
  title: 'Users',
  table: {
    search: 'Search',
    blockedRequestPreAnalysis: 'Pre-analysis request block until',
    name: 'Name',
    groups: 'Groups',
    actions: 'Actions',
  },
};
