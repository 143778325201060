export default {
  subtitulo:
  `
  Documento elaborado por um Responsável Técnico habilitado e apresentado pelo
  operador do aeródromo, que altera a configuração da infraestrutura
  aeroportuária incluindo as características físicas e/ou operacionais dos
  aeródromos ou helipontos.`,
  texto: '',
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
