export default {
  bem_vindo: 'Welcome to the Portal AGA ',
  abrir_processo: 'OPEN PROCESS',
  consultar_planos: 'QUERY PLANS',
  fechar: 'Close',
  planos_projetos: 'Planos e Projetos',
  texto: `The AGA Portal provides information on Aerodrome Protection Zone Plans (PZPA),
            consultation of published plans, reference legislation and also,
            gives access to files that assist in the preparation of processes.
            The AGA Portal provides access to SYSAGA,
            system for preparing and consulting processes.`,
  cardPlanos: {
    titulo: 'Protection Zone Plans',
    texto: 'Consult and learn more about the plans.',
  },
  cardAnalise: {
    titulo: 'Analysis of OPEA or Aerodrome Projects',
    texto: 'What type of your project?',
  },
  cardSysaga: {
    titulo: 'Need to file a case through SYSAGA?',
    texto: 'Login or register an account on the system.',
  },
};
