import NProgress from 'nprogress'; // progress bar
import CryptoJS from 'crypto-js';
import router from './index';
import store from '../store';
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

/**
 * Verifica se o usuário tem permissão para essas rotas.
 * @param {Array} rolesUser - permissões do usuário
 * @param {Array} rolesRoute - permissões da rota
 */
function hasPermission(rolesUser, rolesRoute) {
  if (rolesUser.includes('Desenvolvedor')) return true;
  if (!rolesRoute) return true; // Se a rota não tem roles, pode acessar.
  return rolesUser.some((roleUser) => rolesRoute.includes(roleUser));
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  try {
    // Verifica se a rota precisa de autenticação
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // Verifica se usuário está autenticado
      if (router.app.$keycloak.authenticated) {
        // Verifica se roles da rota está nos roles do vuex
        if (hasPermission(store.getters.roles, to.meta.roles)) {
          next();
          // Verifica se há roles encriptados no LocalStorage
        } else if (localStorage.getItem('vva_user')) {
          // Verifica se roles encriptados no LocalStorage é do usuário autenticado
          try {
            const decryptedBytes = CryptoJS.AES.decrypt(localStorage.getItem('vva_user'), router.app.$keycloak.subject);
            const plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
            const user = JSON.parse(plaintext);
            // Verifica se roles da rota está nas roles decriptadas do LocalStorage
            if (hasPermission(user.roles, to.meta.roles)) {
              next();
            } else {
              next('/erro/401');
            }
          } catch (err) {
            localStorage.removeItem('vva_user');
            await store.dispatch('SetGetUserInfoBackend').then(() => {
              next(to.fullPath);
            });
          }
        } else {
          await store.dispatch('SetGetUserInfoBackend').then(() => {
            next(to.fullPath);
          });
        }
      } else {
        window.location.replace(router.app.$keycloak.createLoginUrl({
          redirectUri: process.env.VUE_APP_URL + to.path,
        }));
      }
    } else {
      next();
    }
  } catch (err) {
    console.warn(`[router.beforeEach]\t${to.path}: ${err}`);
  }
  NProgress.done();
});
