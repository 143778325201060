import Vue from 'vue';
import Router from 'vue-router';

/* Router Modules */
import TheLayoutSysaga from '@/views/sysaga/layout/TheLayoutSysaga.vue';
import vuetifyRouter from '@/demo/router/vuetify';
import nestedRouter from '@/demo/router/nested';
import componentsRouter from '@/demo/router/components';
import permissionRouter from './modules/permission';
import errorsRouter from './modules/errors';
import portalRouter from './modules/portal';
import sysagaRouter from './modules/sysaga';

Vue.use(Router);

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   Se `hidden:true` não será exibido no sidebar(padrão é false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: '#',                 if redirect: '#', will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['Desenvolvedor','Interno','Externo'] control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

/** Rotas que não necessitam de autenticação e que serão incluídas na publicRoutes */
export const constantRoutes = [
  ...portalRouter,
  ...sysagaRouter,
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Administrador'], // Permite ocultar no Drawer
    },
    children: [
      {
        path: '/documentation',
        component: () => import('@/views/sysaga/pages/Documentation'),
        name: 'Documentation',
        meta: {
          title: 'route.documentation', roles: ['Administrador'], icon: 'mdi-text-subject', noCache: true, affix: true, requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Administrador'],
    },
    children: [
      {
        path: '/guide',
        component: () => import('@/views/sysaga/pages/Guide'),
        name: 'Guide',
        meta: {
          title: 'route.guide', roles: ['Administrador'], icon: 'mdi-near-me', noCache: true, affix: true, requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Administrador'],
    },
    children: [
      {
        path: '/roadmap',
        component: () => import('@/views/sysaga/pages/Roadmap'),
        name: 'Roadmap',
        meta: {
          title: 'route.roadmap', roles: ['Administrador'], icon: 'mdi-timeline-text', noCache: true, affix: true, requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Administrador'],
    },
    children: [
      {
        path: '/theme',
        component: () => import('@/views/sysaga/pages/Theme.vue'),
        name: 'Theme',
        meta: {
          title: 'route.theme', roles: ['Administrador'], icon: 'mdi-palette', requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Administrador'],
    },
    children: [
      {
        path: '/i18n',
        component: () => import('@/views/sysaga/pages/I18n.vue'),
        name: 'I18n',
        meta: {
          title: 'route.i18n', roles: ['Administrador'], icon: 'mdi-translate', requiresAuth: true,
        },
      },
    ],
  },
  vuetifyRouter,
  ...componentsRouter,
  nestedRouter,
  permissionRouter,
  ...errorsRouter,

  // Exibe página de erro se usuário tentar acessar rota inexistente na Aplicação
  { path: '*', redirect: '/erro/404', hidden: true },
];

export default new Router({
  mode: 'history',
  linkActiveClass: 'highlighted',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
