import components from '@/demo/locale/es_ES/components';
import preanalise from '@/views/portal/locale/es_ES/preanalise';
import inicio from '@/views/portal/locale/es_ES/inicio';
import planos from '@/views/portal/locale/es_ES/planos';
import documentos from '@/views/portal/locale/es_ES/documentos';
import legislacao from '@/views/portal/locale/es_ES/legislacao';
import duvidas from '@/views/portal/locale/es_ES/duvidas';
import sysaga from '@/views/portal/locale/es_ES/sysaga';
import tac from '@/views/portal/locale/es_ES/tac';
import inscricao from '@/views/portal/locale/es_ES/inscricao';
import alteracao from '@/views/portal/locale/es_ES/alteracao';
import exploracao from '@/views/portal/locale/es_ES/exploracao';
import planoDiretor from '@/views/portal/locale/es_ES/planoDiretor';
import opea from '@/views/portal/locale/es_ES/opea';
import aerodromo from '@/views/portal/locale/es_ES/aerodromo';
import footer from '@/views/portal/locale/es_ES/footer';
import logs from '@/views/sysaga/locale/es_ES/logs';
import user from '@/views/sysaga/locale/es_ES/user';
import denuncias from '@/views/sysaga/locale/es_ES/denuncias';
import guideIndex from '@/views/common/locale/es_ES/guideIndex';
import route from './route';

export default {
  route,
  inicio,
  preanalise,
  denuncias,
  planos,
  guideIndex,
  documentos,
  legislacao,
  duvidas,
  sysaga,
  tac,
  inscricao,
  alteracao,
  exploracao,
  planoDiretor,
  opea,
  aerodromo,
  footer,
  logs,
  user,

  components,
  toolbar: {
    appname: 'SYSAGA',
    settings: 'Configuración',
    profile: 'Datos personales',
    logout: 'Cerrar sesión',
  },
  settings: {
    title: 'Configuración de temas',
    default: 'Configuración predeterminada',
    version: 'Versión',
    position: 'Posición de los elementos.',
    theme: 'Tema',
    dark: 'Tema escuro',
    toolbarDense: 'Toolbar denso',
    navbarDense: 'Navbar denso',
    navbarLogo: 'Navbar Logo',
    settingsBtn: 'Botón de config',
    footer: 'Pie de página',
  },
  errors: {
    whoops: 'Ops',
    back: '¡Sácame de aquí!',
    301: 'Movido Permanentemente',
    401: 'No autorizado',
    403: 'Prohibido',
    404: 'No encontrado',
    500: 'Error interno de servidor',
  },
  guide: {
    description: 'La página de guía es útil para algunas personas que ingresaron al proyecto por '
      + 'primera vez. Puede presentar brevemente las características del proyecto. La demostración se basa en',
    button: 'Mostrar guía',
  },
  ui: {
    switch: 'Cambiar idioma',
    theme: 'Cambiar tema',
    success: 'Éxito',
    error: 'Error',
    warning: 'Advertencia',
    info: 'Información',
    primary: 'Primario',
    secondary: 'Secundario',
    default: 'Predeterminado',
    accent: 'Acento',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'E-mail',
  },
  button: {
    search: 'Buscar',
    send: 'Enviar',
    resend: 'Reenviar',
    consult: 'Consultar',
    remove: 'Para eliminar',
    delete: 'Eliminar',
    clear: 'Limpar',
    create: 'Crear',
    update: 'Actualizar',
    request: 'Solicitud',
    next: 'Próximo',
    previous: 'Anterior',
    enter: 'Iniciar sesión',
    exit: 'Sal',
    close: 'Cerca',
    openProcess: 'Proceso abierto',
    singIn: 'Iniciar sesión',
    singUp: 'Registrarse',
    requestPreAnalisys: 'Preanálisis de solicitud',
    helpCenter: 'Centro de Ayuda',
    contactUs: 'Hable con Nosotros',
  },
  /**
   * TODO: Cavalcanti, favor colocar as linha abaixo no padrão
   * camelCase, em inglês, e traduzido, dentro da chave button
   * localizada logo acima. Caso já exista em button, remova a
   * linha e altere o caminho no componente.
  */
  clearFilters: 'Limpar filtros',
  fetchSameValueInMultipleFieldsValue: 'Busca',
  leiaMais: 'Leia mais',
  Filtros: 'Filtros',
  moreFilters: 'Mais filtros',
  Fechar: 'Fechar',
  columns: 'Colunas',
  lista_campos_busca: 'Busca nos seguintes campos',
  actions: 'Ações',
  Confirmo: 'Confirmo',
  closeBtnText: 'Fechar',
  nextBtnText: 'Próximo',
  prevBtnText: 'Anterior',
  doneBtnText: 'Finalizar',
};
