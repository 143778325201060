export default {
  titulo: 'Aerodrome Project',
  subtitulo: '',
  texto: 'Select the project sub-type:',

  cardAlteracao: {
    titulo: 'Registration change',
    subtitulo: 'Editable file for public interest processes',
  },
  cardExploracao: {
    titulo: 'Operation of Public Civil Aerodromes',
    subtitulo: 'Editable file for public interest processes',
  },
  cardInscricao: {
    titulo: 'Subscription',
    subtitulo: 'Editable file for public interest processes (Annexes C5 and C6 to ICA 11-3)',
  },
  cardPlanoDiretor: {
    titulo: 'Airport Master Plan',
    subtitulo: 'Editable file for public interest processes (Annexes C5 and C6 to ICA 11-3)',
  },
};
