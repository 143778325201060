import colors from 'vuetify/es5/util/colors';

/*
 * Vuetify theme options.
 */
const themes = [
  {
    light: {
      primary: '#089BCE',
      secondary: '#696969',
      accent: '#FF6433',
      success: '#00A06B',
      error: '#CC001B',
      warning: '#FFAE00',
      info: '#6697FF',
    },
    dark: {
      primary: '#0648d6',
      secondary: '#a3a3a3',
      accent: '#00a6fb',
      success: '#4CAF50',
      error: '#ed3d31',
      warning: '#ff7500',
      info: '#ecc30b',
    },
  },
  {
    light: {
      primary: '#003da5',
      secondary: colors.purple.base,
      accent: colors.lightBlue.base,
      success: colors.teal.base,
      error: colors.red.base,
      warning: '#e0ca3c',
      info: '#ffb86f',
    },
    dark: {
      primary: '#003da5',
      secondary: colors.purple.base,
      accent: colors.lightBlue.base,
      success: colors.teal.base,
      error: colors.red.base,
      warning: '#e0ca3c',
      info: '#ffb86f',
    },
  },
  {
    light: {
      primary: '#002166',
      secondary: colors.green.base,
      accent: colors.indigo.base,
      success: colors.lime.darken3,
      error: colors.red.base,
      warning: colors.brown.base,
      info: colors.cyan.base,
    },
    dark: {
      primary: '#0648d6',
      secondary: colors.green.base,
      accent: colors.indigo.base,
      success: colors.lime.darken3,
      error: colors.red.base,
      warning: colors.brown.base,
      info: colors.cyan.base,
    },
  },
  {
    light: {
      primary: '#0a2e86',
      secondary: colors.cyan.base,
      accent: colors.blueGrey.base,
      success: colors.teal.base,
      error: colors.brown.base,
      warning: colors.amber.base,
      info: colors.indigo.base,
    },
    dark: {
      primary: '#001033',
      secondary: colors.cyan.base,
      accent: '#06d6a0',
      success: '#60c13f',
      error: '#ce0058',
      warning: colors.amber.base,
      info: '#f46036',
    },
  },
  {
    light: {
      primary: '#202c5f',
      secondary: colors.cyan.base,
      accent: colors.purple.base,
      success: colors.teal.base,
      error: colors.pink.base,
      warning: '#CC8B00',
      info: '#0041CC',
    },
    dark: {
      primary: '#202c5f',
      secondary: colors.cyan.base,
      accent: colors.purple.base,
      success: colors.teal.base,
      error: colors.pink.base,
      warning: '#CC8B00',
      info: '#0041CC',
    },
  },
  {
    light: {
      primary: '#001033',
      secondary: colors.blueGrey.darken3,
      accent: colors.lightBlue.darken3,
      success: colors.green.darken3,
      error: colors.red.darken3,
      warning: colors.amber.darken3,
      info: colors.cyan.darken3,
    },
    dark: {
      primary: '#2a1e5c',
      secondary: colors.blueGrey.darken3,
      accent: colors.lightBlue.darken3,
      success: colors.green.darken3,
      error: colors.red.darken3,
      warning: colors.amber.darken3,
      info: colors.cyan.darken3,
    },
  },
  {
    light: {
      primary: '#00274c', // #1976D2
      secondary: '#2d2926', // #424242
      accent: '#00a6fb', // #82B1FF
      success: '#006647', // #4CAF50
      error: '#C8102E', // #FF5252
      warning: '#F2A900', // #FFC107
      info: '#99CAEA', // #2196F3
    },
    dark: {
      primary: '#00274c',
      secondary: '#2d2926',
      accent: '#00a6fb',
      success: '#006647',
      error: '#C8102E',
      warning: '#F2A900',
      info: '#99CAEA',
    },
  },
  {
    light: {
      primary: '#003322',
      secondary: '#50514F',
      accent: colors.lightBlue.base,
      success: '#70C1B3',
      error: '#F25F5C',
      warning: '#E0CA3C',
      info: '#CCC5B9',
    },
    dark: {
      primary: '#003322',
      secondary: '#50514F',
      accent: colors.lightBlue.base,
      success: '#70C1B3',
      error: '#F25F5C',
      warning: '#E0CA3C',
      info: '#6e2594',
    },
  },
];
export default themes;
