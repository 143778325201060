export default {
  bem_vindo: 'Bem-vindo ao Portal AGA',
  abrir_processo: 'ABRIR PROCESSO',
  consultar_planos: 'CONSULTAR PLANOS',
  fechar: 'Fechar',
  planos_projetos: 'Planos e Projetos',
  texto: `O Portal AGA disponibiliza informações sobre Planos de Zona de Proteção de Aeródromos (PZPA), 
          consulta aos planos publicados, legislação de referência e, também, 
          dá acesso a arquivos que auxiliam a elaboração de processos. 
          O Portal AGA disponibiliza acesso ao SYSAGA, 
          sistema destinado à elaboração e à consulta de processos.`,
  card_planos: {
    titulo: 'Planos de Zona de Proteção',
    texto: `
              Acesse os planos de zona de proteção aprovados pelo DECEA,
              selecionando um aeródromo de interesse ou um município específico
              para saber qual plano de zona de proteção impõe restrição ao
              município/Distrito. É possível buscar por cidade ou
              diretamente pelo nome do aerodrómo.`,
    botao: 'Consultar Plano',
  },
  card_opea: {
    titulo: 'Projeto de OPEA',
    texto: `
Para projetos de Objeto projetado no espaço aéreo (OPEA), de qualquer natureza, 
temporária ou permanente, 
fixa ou móvel, é sujeito à análise sob os aspectos 
de uso do espaço aéreo nacional, utilizando parâmetros 
estabelecidos na ICA 11-408. `,
    botao: 'Acessar seção',
  },
  card_ad: {
    titulo: 'Precisa abrir um processo pelo SYSAGA?',
    texto: 'Entre ou crie uma conta no sistema.',
    botao: 'Consultar Planos',
  },
  cardSysaga: {
    titulo: 'Precisa abrir um processo pelo SYSAGA?',
    texto: 'Entre ou crie uma conta no sistema.',
    botao: 'Solicitar Pré-análise',
  },
};
