import TheLayoutSysaga from '@/views/sysaga/layout/TheLayoutSysaga.vue';

const sysagaRouter = [
  {
    path: '/',
    component: TheLayoutSysaga,
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/sysaga/pages/Dashboard/index'),
        name: 'Dashboard',
        meta: {
          title: 'route.dashboard',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Interno'], // Permite ocultar no Drawer
    },
    children: [
      {
        path: '/denuncias',
        component: () => import('@/views/sysaga/pages/Denuncia/Index'),
        name: 'Denuncia',
        meta: {
          title: 'route.complaints',
          roles: ['Interno'],
          icon: 'mdi-alarm-light',
          noCache: true,
          affix: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Interno'], // Permite ocultar no Drawer
    },
    children: [
      {
        path: '/fluxograma',
        component: () => import('@/views/sysaga/pages/Fluxograma/Index'),
        name: 'Fluxograma',
        meta: {
          title: 'route.fluxograma',
          roles: ['Interno'],
          icon: 'mdi-arrange-bring-to-front',
          noCache: true,
          affix: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    hidden: true,
    children: [
      {
        path: '/logs',
        component: () => import('@/views/sysaga/pages/Logs/Index.vue'),
        name: 'Logs',
        meta: {
          title: 'Logs',
          roles: ['Desenvolvedor'],
          icon: 'mdi-bug',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutSysaga,
    meta: {
      roles: ['Desenvolvedor', 'Administrador'], // Permite ocultar no Drawer
    },
    children: [
      {
        path: '/user',
        component: () => import('@/views/sysaga/pages/User/Index.vue'),
        name: 'User',
        meta: {
          title: 'route.user',
          roles: ['Desenvolvedor', 'Administrador'],
          icon: 'mdi-account-group',
          requiresAuth: true,
        },
      },
    ],
  },
];

export default sysagaRouter;
