export default {
  subtitulo:
  `
  Documento preparado por el operador del aeródromo, que establece la
  planificación para la expansión de infraestructura aeroportuaria en
  línea con el regulaciones de seguridad operacional emitido por ANAC.
  Este documento, para los propósitos de ICA 11-3, debe ser preparado
  por gerentes técnicos debidamente calificado.
  `,
  texto: '',
  cardDocumentacao: {
    titulo: 'Documentación relacionada',
    texto: '',
  },
};
