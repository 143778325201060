<template>
  <v-navigation-drawer
    dark
    fixed
    app
    :value="navbarShow"
    width="250"
    color="primary"
    @input="stateNavbarShow"
  >
    <v-app-bar
      v-if="navbarLogo"
      :dense="toolbarDense"
      dark
      color="primary"
    >
      <v-toolbar-title class="text-center">
        <v-avatar
          size="42px"
          tile
        >
          <img
            :src="logoAga"
            alt="Logo AGA"
            width="42"
            height="42"
          >
        </v-avatar>
        <span> {{ $t('toolbar.appname') }}</span>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-sysaga-drawer-list
      :dense="navbarDense"
      :routes="permissionRoutes"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLayoutSysagaDrawerList from './TheLayoutSysagaDrawerList.vue';

export default {
  name: 'TheLayoutSysagaDrawer',
  components: {
    TheLayoutSysagaDrawerList,
  },
  data: () => ({
    logoAga: `${process.env.VUE_APP_URL}/img/logos/aga.png`,
  }),
  computed: {
    ...mapGetters([
      'permissionRoutes',
      'navbarDense',
      'navbarShow',
      'navbarLogo',
      'toolbarDense',
    ]),
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch('NavbarState', { state });
    },
  },
};
</script>
