export default {
  subtitulo:
  `
  Documento preparado por un Responsable Técnico calificado y presentado por
  el operador del aeródromo, que cambia la configuración de la
  infraestructura del aeropuerto, incluidas las características físicas
  y / u operativas de los aeródromos o helipuertos.
  `,
  texto:
  `
  Los aeródromos registrados en el registro ANAC que no presentaron sus Planes
  de Zona de Protección a DECEA deben usar este proceso para la regularización
  con COMAER, según lo dispuesto en el Artículo 129 de la Ordenanza
  957 / GC3 de 2015.
  `,
  cardDocumentacao: {
    titulo: 'Documentación relacionada',
    texto: '',
  },
};
