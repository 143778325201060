import TheLayoutPortal from '@/views/portal/layout/TheLayoutPortal.vue';

const portalRouter = [
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/inicio',
        alias: '/',
        component: () => import('@/views/portal/pages/Inicio'),
        name: 'Inicio',
        meta: {
          title: 'route.portal.inicio.title',
          icon: 'mdi-home',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/opea',
        component: () => import('@/views/portal/pages/Opea'),
        name: 'Opea',
        meta: {
          title: 'route.portal.opea.title',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/aerodromo',
        component: () => import('@/views/portal/pages/Aerodromo'),
        name: 'Aerodromo',
        meta: {
          title: 'route.portal.aerodromo.title',
          icon: 'mdi-airplane-landing',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/aerodromo/alteracao',
        component: () => import('@/views/portal/pages/AerodromoAlteracao.vue'),
        name: 'Alteracao',
        meta: {
          title: 'route.portal.alteracao.title',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/aerodromo/exploracao',
        component: () => import('@/views/portal/pages/AerodromoExploracao.vue'),
        name: 'Exploracao',
        meta: {
          title: 'route.portal.exploracao.title',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/aerodromo/plano-diretor',
        component: () => import('@/views/portal/pages/AerodromoPlanoDiretor.vue'),
        name: 'PlanoDiretor',
        meta: {
          title: 'route.portal.planoDiretor.title',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/aerodromo/inscricao',
        component: () => import('@/views/portal/pages/AerodromoInscricao.vue'),
        name: 'Inscricao',
        meta: {
          title: 'route.portal.inscricao.title',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/planos',
        component: () => import('@/views/portal/pages/Planos'),
        name: 'Planos',
        meta: {
          title: 'route.portal.planos.title',
          icon: 'mdi-floor-plan',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/documentos',
        component: () => import('@/views/portal/pages/Documentos'),
        name: 'Documentos',
        meta: {
          title: 'route.portal.documentos.title',
          icon: 'mdi-download',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/legislacao',
        component: () => import('@/views/portal/pages/Legislacao'),
        name: 'Legislação',
        meta: {
          title: 'route.portal.legislacao.title',
          icon: 'mdi-notebook-multiple',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/preanalise',
        component: () => import('@/views/portal/pages/Preanalise'),
        name: 'Preanalise',
        meta: {
          title: 'route.portal.preanalise.title',
          icon: 'mdi-eye',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/tac',
        component: () => import('@/views/portal/pages/Tac'),
        name: 'Tac',
        meta: {
          title: 'route.portal.tac.title',
          icon: 'mdi-file-document',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: true,
    children: [
      {
        path: '/sysaga',
        component: () => import('@/views/portal/pages/Sysaga'),
        name: 'Sysaga',
        meta: {
          title: 'route.portal.sysaga.title',
          icon: 'mdi-home-analytics',
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/duvidas',
        component: () => import('@/views/portal/pages/Duvidas'),
        name: 'Sysaga',
        meta: {
          title: 'route.portal.sysaga.title',
          icon: false,
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: TheLayoutPortal,
    hidden: true,
    portal: false,
    children: [
      {
        path: '/atendimento',
        component: () => import('@/views/portal/pages/Atendimento'),
        name: 'Sysaga',
        meta: {
          title: 'route.portal.sysaga.title',
          icon: false,
          noCache: true,
          affix: true,
          requiresAuth: false,
        },
      },
    ],
  },
];

export default portalRouter;
