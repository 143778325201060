export default {
  titulo: '¿Qué es el preanálisis?',
  texto:
  `
  El preanálisis consiste en una verificación basada en los criterios para
  Solicitud de análisis de objeto proyectado en espacio aéreo que no
  se contemplan en el Capítulo VII de la Ordenanza 957 / GC3, de 09
  Julio de 2015. Con la facilidad de preanálisis, el solicitante es suficiente
  enviar información básica de coordenadas, altitud base,
  altura del objeto (que le permitirá alcanzar la altitud máxima,
  posicionado en relación con un plan básico de un aeródromo existente
  que rodea el proyecto), para averiguar si podría o no
  construir en esa área. Con esto, la herramienta evita la apertura
  procesos innecesarios, ya que genera un documento de inaplicabilidad,
  Si el resultado del análisis es positivo. Si no, el
  el solicitante recibirá información que necesitará para presentar un caso
  a través del Sistema de Gestión de Procesos del Área AGA (SYSAGA).
  Desde el 10/01/2018 el preanálisis se convirtió en una etapa inicial
  en SYSAGA
  `,
  consulta: {
    titulo: 'Consulta de solicitudes de ANÁLISIS PREVIO:',
    label: 'Número de protocolo',
  },
};
