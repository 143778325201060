<template>
  <v-navigation-drawer
    dark
    app
    :value="navbarShow"
    class="primary"
    @input="stateNavbarShow"
  >
    <v-app-bar
      dark
      class="primary"
    >
      <v-toolbar-title class="text-center">
        <v-avatar
          size="42px"
          tile
        >
          <img
            src="img/logos/aga.png"
            alt="Logo Portal Aeródromos"
            width="42"
            height="42"
          >
        </v-avatar>
        <span> Portal Aeródromos</span>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-portal-drawer-list
      dense
      :routes="routes"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLayoutPortalDrawerList from './TheLayoutPortalDrawerList.vue';

export default {
  name: 'TheLayoutPortalDrawer',
  components: {
    TheLayoutPortalDrawerList,
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'routes',
      'permissionRoutes',
      'navbarDense',
      'navbarShow',
      'navbarLogo',
      'toolbarDense',
    ]),
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch('NavbarState', { state });
    },
  },
};
</script>
