export default {
  titulo: 'Proyecto de Objeto Proyectado en Aspacio Aéreo (OPEA)',
  subtitulo:
  `
  Objeto, de cualquier naturaleza, temporal o permanente, fijo o móvil,
  sujeto a análisis bajo los aspectos de uso del espacio aéreo nacional,
  utilizando los parámetros establecidos en la ICA 11-408 y en una
  norma complementaria de COMAER.
  `,
  texto:
  `
  El preanálisis consiste en una herramienta de análisis para
  Process Management AGA (SYSAGA), donde el
  interesado en construir edificios y eso estaría exento
  para consultar el Comando de la Fuerza Aérea (COMAER), envíe el
  información básica de coordenadas, altitud base y altura
  del objeto De este preanálisis, la parte interesada puede
  recibir un documento de COMAER de inaplicabilidad o un aviso
  La necesidad de abrir un caso. Acceda a SYSAGA para
  iniciar un preanálisis o abrir un caso.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
