import en from 'vuetify/es5/locale/en';
import pt from 'vuetify/es5/locale/pt';
import es from 'vuetify/es5/locale/es';

const locales = {
  en,
  pt,
  es,
};

export default locales;
