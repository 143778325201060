export default {
  titulo: 'AGA Process Management System',
  texto: {
    parte1:
    `SysAGA is a system developed by DECEA to manage the processes
     defined in ICA 11-3 at the national level.`,
    parte2:
    `Through SysAGA, users will be able to fill out: requirements, Aerodrome
     or Helipad or Navigation Aid Information Sheets, longitudinal profile
     elevation spreadsheet, topographic information and document checklists,
     as well as send them to DECEA Regional Units. The system also allows you to check the
    progress of the processes under the responsibility of a specific user.`,
    parte3:
    `Any person, whether natural or legal, interested in submitting one
    of the processes established in ICA 11-3 or ICA 11-4 to the analysis of DECEA Regional
     Units must do so through SysAGA, at https://sysaga2.decea.mil.br/.

Registration with SysAGA must be carried out by the interested party at the email
 address above. The user will define his/her login and choose a password during registration.
  After registration, the user will receive, via registered email, a link to activate the account.
  After activation, you will be able to submit one of the processes
  set out in ICA 11-3 or ICA 11-4 for analysis by DECEA Regional Units.

If you have any doubts about which process to start, click the "Open Process"
option on the AGA Portal homepage (https:servicos.decea.mil.br/aga).`,
  },
  cardLogin: 'Access or Create an Account on the System',
};
