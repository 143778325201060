export default {
  titulo: 'Sistema de Gerenciamento de Processos AGA',
  texto: {
    parte1:
    `O SysAGA é um sistema desenvolvido pelo DECEA para gerenciamento dos processos
     definidos na ICA 11-3 e na ICA 11-4 no âmbito nacional.`,
    parte2:
    `Por meio do SysAGA, os usuários poderão preencher: requerimentos, Fichas Informativas
    de Aeródromos ou Helipontos ou Auxílios à Navegação Aérea, Planilha das elevações do
    perfil longitudinal, informações topográficas e listas de verificação de documentos,
    bem como enviá-los aos Órgãos Regionais do DECEA. O sistema permite, ainda, a realização
    de consultas sobre o
    andamento dos processos sob a responsabilidade de um determinado usuário.`,
    parte3:
    `Qualquer pessoa, física ou jurídica, interessada em submeter um dos processos
     estabelecidos na ICA 11-3 ou na ICA 11-4 à análise dos Órgãos Regionais do DECEA
     deverá fazê-lo por meio do SysAGA, no endereço eletrônicohttps://sysaga2.decea.mil.br/.

O cadastramento no SysAGA deverá ser realizado pelo próprio interessado no endereço
eletrônico acima. O usuário definirá seu login e escolherá uma senha durante o cadastro.
Após o cadastramento,o usuário receberá, no e-mail cadastrado, um link para ativação da conta.
 Após a ativação, estará apto a submeter um dos processos estabelecidos
 na ICA 11-3 ou na ICA 11-4
 à análise dos Órgãos Regionais do DECEA.

Caso tenha dúvidas sobre qual processo iniciar, clique na opção "ACESSAR SEÇÃO"
na opção Projeto de OPEA ou Projeto de Aeródromo na página inicial do Portal AGA (https:servicos.decea.mil.br/aga).`,
  },
  cardLogin: 'Acesse ou Crie uma Conta no Sistema',
};
