export default {
  duvidas_mais_comuns: 'Dúvidas mais comuns',
  p1: 'Separamos as dúvidas mais comuns por tópicos. ',
  p2: 'Caso sua dúvida não seja esclarecida, entre em contato.  ',
  tenho_duvidas: 'TENHO DÚVIDA ',
  entrar_em_contato: 'ENTRAR EM CONTATO ',
  cadastrar: 'CADASTRE-SE',
  assinar: 'DESEJA RECEBER PLANOS',

};
