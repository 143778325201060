export default {
  subtitulo:
  `
  La operación de aeródromos civiles públicos, de conformidad con el Decreto No. 7,624,
  del 22 de noviembre de 2011, y del Decreto No. 7.871, del 21 de diciembre de 2012,
  dependerá del análisis de COMAER de los temas de su competencia, requerido
  como un paso constituyente en el proceso de obtención del instrumento de subvención
  para la operación de aeródromos civiles públicos coordinados por SAC/MInfra.
  `,
  texto:
  `
  La decisión favorable de COMAER para un proceso de exploración de aeródromo
  el servicio civil público tendrá una validez de cinco años con el fin de presentar el
  proceso de registro o cambio en el registro, según sea el caso, por
  interesado.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
