export default {
  subtitulo:
  `
  Document prepared by a qualified Technical Responsible and presented by the
  aerodrome operator, which changes the configuration of the airport
  infrastructure including the physical and / or operational characteristics
  of the aerodromes or heliports.
  `,
  texto: '',
  cardDocumentacao: {
    titulo: 'Related Documentation',
    texto: '',
  },
};
