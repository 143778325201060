export default {
  titulo: 'Project of Object Projected in Airspace (OPEA)',
  subtitulo:
  `
  Object, of any nature, temporary or permanent, fixed or mobile, subject to
  analysis under the aspects of use of national airspace, using the parameters
  established in ICA 11-408 and in a complementary norm from COMAER.
  `,
  texto:
  `
  Pre-analysis consists of an analysis tool for the
  Process Management AGA (SYSAGA), where the
  interested in building buildings and that would be exempt
  to consult the Air Force Command (COMAER), submit the
  basic coordinate information, base altitude and height
  of the object. From this pre-analysis, the interested party can
  receive a COMAER unenforceability document or a notice
  the need to open a case. Access SYSAGA to
  start a pre-analysis or open a case.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
