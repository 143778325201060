export default {
  calendarTitle: 'DATAS COM OCORRÊNCIA DE LOGS',
  filterTitle: 'Filtre por categoria:',
  buttonClearFilter: 'Limpar Filtro',
  table: {
    search: 'Buscar',
    timestamp: 'Data/Hora',
    type: 'Tipo',
    message: 'Mensagem',
  },
};
