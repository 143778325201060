<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import './styles/main.scss';
// Classes de animações https://animate.style/
@import '~animate.css/animate.min.css';

// Nome de Classe usando o padrão BEM http://getbem.com/naming/
.layout__toolbar-footer-background-color{
  background: linear-gradient(90deg,rgba(0, 32, 102, 1), $color-blue-fab 60%, rgba(0, 32, 102, 1));
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background: #002066;
  box-shadow: none;
  .v-toolbar__content {
    padding-left: 0;
    padding-right: 0;
  }
}

// Cor de fundo...
.layout__content-background-color {
  // background-color:#A3B7D1; //Paleta1 #D5C083 #524C49 #08174A #283D6B #5F7EA2 #A3B7D1
  // background-color:#f8f3ea; //Paleta2 #254e7b #5584b1 #85c1e5 #cbe2ef #f8f3ea
  // background-color:#eee; //Paleta3 #d1d1cf rgb(98, 115, 163)
}

// Cor de fundo das páginas de autenticação
.auth__content-background-color {
  // background-color:#A3B7D1; //Paleta1 #D5C083 #524C49 #08174A #283D6B #5F7EA2 #A3B7D1
  // background-color:#f8f3ea; //Paleta2 #254e7b #5584b1 #85c1e5 #cbe2ef #f8f3ea
  background-color:rgba($color: #0033a0, $alpha: .5); //Paleta3 #d1d1cf
}

// Título das páginas do Portal
.layout__content-title-background-color {
  box-shadow: inset 0 0 3.0em #000000, 0 0 1.5em #000000;
  background-color: rgba($color: #0033a0, $alpha: .9);
}
</style>
