export default {
  title: 'Los usuarios',
  table: {
    search: 'Buscar',
    blockedRequestPreAnalysis: 'Solicitud de preanálisis bloque hasta',
    name: 'Nombre',
    groups: 'Grupos',
    actions: 'Comportamiento',
  },
};
