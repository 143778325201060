export default {
  titulo: 'Protection Zone Plans',
  subtitulo: '',
  texto:
  `
  Access the Protection Zone Plans published by DECEA, by selecting an aerodrome
  of interest or a specific municipality/district to find out which Protection
   Zone Plan(s) is/are used to rule land occupation, in order to guarantee the
    safety and regularity of air operations.
  You can search by city or directly by the name of the aerodrome.
  `,
  tipos: {
    titulo: 'Types of Protection Zone Plans',
    pbzpa: {
      titulo: 'Basic Aerodrome Protection Zone Plan (PBZPA)',
      texto:
      `
      A set of obstacle limitation surfaces that establish restrictions on the
      use of properties in the vicinity of an aerodrome.
      `,
    },
    pbzph: {
      titulo: 'Basic Helipad Protection Zone Plan (PBZPH)',
      texto:
      `
      A set of obstacle limitation surfaces that establish restrictions on the use
      of properties in the vicinity of a helipad.
      `,
    },
    pzpana: {
      titulo: 'Air Navigation Aid Protection Zone Plan (PZPANA)',
      texto:
      `
      A set of obstacle limitation surfaces that establish restrictions on
       the use of properties in the vicinity of the navigation aids, needed
       for the operation of the aids, whether they are
       located inside or outside the boundaries of the area of a given aerodrome.
      `,
    },
    pezpa: {
      titulo: 'Specific Aerodrome Protection Zone Plan (PEZPA)',
      texto:
      `
      Specific application document that establishes the restrictions on the
      use of properties in the vicinity of certain aerodromes.
      `,
    },
    pzpreah: {
      titulo: 'Protection Plan for Special Aircraft and Helicopter Routes (PZPREAH)',
      texto:
        `
      Set of obstacle limitation surfaces that establish restrictions on the use
      of properties around special routes for airplanes and helicopters.
      `,
    },
  },
  card: {
    titulo: 'Search plans',
    estado: 'State',
    municipio: 'County',
    oaci: 'Aerodrome name or ICAO code',
  },
  resultados_busca: 'Search results for plans',
  resultado: {
    table_loading: 'Loading ... Please wait',
    table_headers: {
      nome_aerodromo: 'Aerodrome Name',
      data_assinatura: 'Data de Assinatura',
      oaci: 'OACI',
      tipo: 'Type',
      data: 'Date',
      portaria: 'Contact',
      municipios_impactados: 'Impacted municipalities',
      zonas_protecao: 'Protection zones',
    },
  },
};
