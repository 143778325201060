export default {
  fetchSameValueInMultipleFieldsValueEditable:
    {
      title: 'Busca em vários campos',
      description: ' Campo permite busca em diversas colunas do banco ',
    },
  clearFilters:
    {
      title: 'Limpar filtros ',
      description: 'Limpa os filtros da página  ',
    },
  search:
    {
      title: 'Realiza a busca',
      description: ' Botão que executa a consulta ao banco conforme filtros preenchidos ',
    },
  moreFilters:
    {
      title: 'Busca em vários campos',
      description: ' Campo permite busca em diversas colunas do banco: ',
    },
  columns:
    {
      title: 'Mais filtros',
      description: ` Opções adicionais de filtros, mostra um menu' +
        ' naterial a direita da tela com os filtros `,
    },
  mainTable:
    {
      title: 'Tabela principal',
      description: `Tabele que contem os resultados da busca de forma paginada
      , é possivel exporta em diversos formatos e mudar a quantidade de resultados
      por página.`,
    },
  actionColumn:
    {
      title: 'Coluna ações',
      description: `Coluna que contém
     as ações aplicáveis para cada linha
      da tabela.`,
    },
  exportFileCol:
    {
      title: 'Opções de exportação ',
      description: `Lista de opções de formatos
     que é possível exportar os resultados
      segundo filtros selecionados.`,
    },
  pagination:
    {
      title: 'Paginação dos resultados',
      description: `Quanto a quantidade de resuldados
     é maior que a quantidade de resuldados por
      página, a tabela de forma automática,
       disponibiliza a paginação.`,
    },
  resultsPerPage:
    {
      title: 'Quantidade de resultados por página',
      description: ` É a quantidade máxima
      de resuldados por página a ser apresentado na
      página web.`,
    },
};
