export default {
  titulo: 'Documentos Auxiliares',
  subtitulo: '',
  texto: 'Disponibilizamos uma lista de documentos que irão lhe auxiliar no processo:',
  opea: {
    card: {
      assunto: 'Objetos Projetados no Espaço Aéreo',
      explicacao: `Arquivos necessários para abertura de processos relacionados
      a Objetos (OPEA), como construção de edificações, conjunto habitacional,
      loteamento, ponte, viaduto, balão cativo, aterro sanitário, posto de combustível,
      mastros, torres, parques eólicos, linhas
      de transmissão, antenas, projetor de raio laser, entre outros.`,
    },
  },
  aerodromo: {
    card: {
      assunto: 'Aeródromos',
      explicacao: `Arquivos necessários para abertura de processos relacionados a Aeródromos,
       como Inscrição, Alteração, PDIR e Exploração.`,
    },
  },
  apoio: {
    card: {
      assunto: 'Apoio',
      explicacao: 'Arquivos de orientações, tutoriais e links externos ao COMAER.',
    },
  },
};
