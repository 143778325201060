export default {
  calendarTitle: 'DATES WITH OCCURRENCE OF LOGS',
  filterTitle: 'Filter by category:',
  buttonClearFilter: 'Clear Filter',
  table: {
    search: 'Search',
    timestamp: 'Timestamp',
    type: 'Type',
    message: 'Message',
  },
};
