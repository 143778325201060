<template>
  <v-tabs
    background-color="transparent"
    light
    class="tabs-main-menu"
  >
    <template v-for="(route, keyRoute) in routes">
      <template v-if="route && route.portal && route.children[0].meta.title">
        <v-tab
          :key="route.children[0].name + '_' + keyRoute "
          :to="route.children[0].path"
          class="p-0 m-0 font-weight-regular"
        >
          {{ $t(route.children[0].meta.title) }}
        </v-tab>
      </template>
    </template>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheLayoutPortalToolbarTab',
  data: () => ({}),
  computed: {
    ...mapGetters([
      'routes']),
  },
};
</script>

<style lang="scss" scoped>
  .tabs-main-menu {
    .v-tabs-slider-wrapper {
      display: none;
    }
    .v-tab {
      color: #646E82;
      border-radius: 3px;
      &[aria-selected="true"] {
        color: #fff !important;
        background-color: #089BCE;
      }
    }
  }
</style>
