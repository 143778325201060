export default {
  subtitulo:
  `
  Document prepared by the aerodrome operator, that establishes the planning
  for the expansion of  airport infrastructure in line with the operational
  safety regulations issued by ANAC. This document, for the purposes of
  ICA 11-3, should be prepared by technical managers properly qualified.
  `,
  texto: '',
  cardDocumentacao: {
    titulo: 'Related Documentation',
    texto: '',
  },
};
