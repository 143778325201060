<template>
  <router-link to="/logs">
    <v-btn
      color="error"
      icon
    >
      <v-icon>mdi-bug</v-icon>
    </v-btn>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppErrorLog',
  computed: {
    ...mapGetters([]),
  },
  methods: {
  },
};
</script>
