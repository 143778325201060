/** When your routing table is too long, you can split it into small modules */

import ErrorPage from '@/views/sysaga/pages/ErrorPage.vue';

const errorsRouter = [
  {
    path: '/erro/301',
    component: ErrorPage,
    name: 'Page301',
    meta: { title: 'route.errorPages.page301', noCache: true },
    props: { errorCode: 301 },
    hidden: true,
    requiresAuth: false,
  },
  {
    path: '/erro/401',
    component: ErrorPage,
    name: 'Page401',
    meta: { title: 'route.errorPages.page401', noCache: true },
    props: { errorCode: 401 },
    hidden: true,
    requiresAuth: false,
  },
  {
    path: '/erro/403',
    component: ErrorPage,
    name: 'Page403',
    meta: { title: 'route.errorPages.page403' },
    props: { errorCode: 403 },
    hidden: true,
    requiresAuth: false,
  },
  {
    path: '/erro/404',
    component: ErrorPage,
    name: 'Page404',
    meta: { title: 'route.errorPages.page404' },
    props: { errorCode: 404 },
    hidden: true,
    requiresAuth: false,
  },
  {
    path: '/erro/500',
    component: ErrorPage,
    name: 'Page500',
    meta: { title: 'route.errorPages.page500' },
    props: { errorCode: 500 },
    hidden: true,
    requiresAuth: false,
  },
];

export default errorsRouter;
