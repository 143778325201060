export default {
  titulo: 'Sistema de Gestión de Procesos AGA',
  texto: {
    parte1:
    `SysAGA es un sistema desarrollado por el DECEA para gestionar los procesos
     definidos en la ICA 11-3 a nivel nacional.`,
    parte2:
    `Por medio del SysAGA, los usuarios podrán hacer: solicitudes, Fichas
     Informativas de Aeródromos o Helipuertos o Ayuda a la Navegación Aérea,
      Planillas de las Elevaciones del Perfil Longitudinal, información topográfica
       y listas de verificación de documentos, así como enviarlas a los órganos regionales
       del DECEA . El sistema también permite realizar consultas sobre el
     estado de los procesos bajo la responsabilidad de un usuario específico.`,
    parte3:
    `Cualquier persona, natural o jurídica, interesada en presentar
     uno de los procesos establecidos en la ICA 11-3 o la ICA 11-4 para que
      sea analizado por los Órganos Regionales del DECEA debe hacerlo a través
       del SysAGA, en la dirección URL https://sysaga2.decea.mil.br/.

El propio interesado debe realizar el registro en el SysAGA, en la dirección URL anterior.
 El usuario definirá su login y elegirá una contraseña durante el registro. Después del
 registro, el usuario recibirá, en el correo electrónico registrado, un enlace para activar
 la cuenta. Después de la activación, podrá enviar uno de los procesos establecidos en
 la ICA 11-3 o la ICA 11-4 para su análisis por parte de los Organismos Regionales del DECEA

Caso tenga duda sobre cual proceso empezar, clique en la opción “Empezar Proceso” en
la hoja inicial del Portal AGA (https:servicos.decea.mil.br/aga).`,
  },
  cardLogin: 'Acceda o cree una cuenta en el sistema',
};
