export default {
  titulo: 'Conduct Adjustment Agreements (TAC)',
  subtitulo: '',
  texto:
  `
  A Conduct Adjustment Agreement is a document signed between the Aerodrome
  Operator or its designated representative and DECEA, for those aerodromes
  that have been closed,
  or are expected to close, due to non-submission of the Basic Protection Zone Plan.
  `,
  card: {
    titulo: 'Search terms',
    estado: 'State',
    municipio: 'Municipality',
    oaci: 'Search for Aerodrome (ICAO Name or Code)',
  },
  resultados_busca: 'Search results for TAC',
  resultado: {
    table_loading: 'Loading ... Please wait',
    table_headers: {
      nome_aerodromo: 'Aerodrome Name',
      data_assinatura: 'Data de Assinatura',
      dou: 'DOU',
      oaci: 'OACI',
      tipo: 'Type',
      data: 'Date',
      validade: 'Shelf life',
    },
  },
};
