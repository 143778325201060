<template>
  <v-footer
    v-if="footerShow"
    app
    padless
    inset
  >
    <v-card
      flat
      tile
      width="100%"
      class="text-center primary"
      style="min-width: 320px"
    >
      <!-- telaAcima890px -->
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="telaAcima890px"
      >
        <v-col cols="4">
          <div
            class="d-flex justify-start ml-4"
          >
            <a
              target="_blank"
              href="https://www.decea.gov.br/"
            >
              <v-img
                src="/img/logos/logo-decea.png"
                height="45"
                width="190"
              />
            </a>
          </div>
        </v-col>
        <v-col cols="4">
          <v-btn
            v-for="link in links"
            :key="link.url"
            class="transparent pa-0 ma-1"
            style="box-shadow: none"
            dark
          >
            <a
              :target="'_blank'"
              :href="link.url"
            >
              <v-icon
                size="18px"
                class="white--text"
              >
                {{ link.icon }}
              </v-icon>
              <div>
                <span class="white--text text-caption font-weight-light">
                  {{ link.description }}
                </span>
              </div>
            </a>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <div
            class="d-flex justify-end mr-4"
          >
            <a
              target="_blank"
              href="https://www.fab.mil.br/"
            >
              <v-img
                src="/img/logos/logo-fab-horizontal-branco-3d.png"
                height="50"
                width="255"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <!-- telaAbaixo890px landscape -->
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="telaAbaixo890px landscape"
      >
        <v-col cols="12">
          <v-btn
            v-for="link in links"
            :key="link.url"
            class="transparent pa-0 ma-1"
            style="box-shadow: none"
            dark
          >
            <a
              :target="'_blank'"
              :href="link.url"
            >
              <v-icon
                size="14px"
                class="white--text"
              >
                {{ link.icon }}
              </v-icon>
              <div>
                <span class="white--text text-caption font-weight-light">
                  {{ link.description }}
                </span>
              </div>
            </a>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <div
            style="margin-top: 5px"
            align="center"
          >
            <a
              target="_blank"
              href="https://www.decea.gov.br/"
            >
              <v-img
                src="/img/logos/dom-decea.png"
                height="28"
                width="22"
              />
            </a>
          </div>
        </v-col>
        <v-col cols="4">
          <div
            style="margin-top: 5px"
            align="center"
          >
            <a
              target="_blank"
              href="https://www.fab.mil.br/"
            >
              <v-img
                src="/img/logos/logo-fab-vertical-branco-72x72.png"
                height="30"
                width="30"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <!-- telaAbaixo890px portrait -->
      <v-row
        no-gutters
        style="flex-wrap: nowrap;"
        align="center"
        justify="center"
        class="telaAbaixo890px portrait"
      >
        <v-col cols="2">
          <div
            style="margin-top: 5px"
            align="center"
          >
            <a
              target="_blank"
              href="https://www.decea.gov.br/"
            >
              <v-img
                src="/img/logos/dom-decea.png"
                height="30"
                width="25"
              />
            </a>
          </div>
        </v-col>
        <v-col cols="8">
          <v-btn
            v-for="link in links"
            :key="link.url"
            class="transparent pa-0 ma-1"
            style="box-shadow: none"
            dark
          >
            <a
              :target="'_blank'"
              :href="link.url"
            >
              <v-icon
                size="14px"
                class="white--text"
              >
                {{ link.icon }}
              </v-icon>
              <div>
                <span class="white--text text-overline">{{ link.description }}</span>
              </div>
            </a>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <div
            style="margin-top: 5px"
            align="center"
          >
            <a
              target="_blank"
              href="https://www.fab.mil.br/"
            >
              <v-img
                src="/img/logos/logo-fab-vertical-branco-72x72.png"
                height="40"
                width="40"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <v-card-text class="py-2 white--text text-center text-overline font-weight-light">
        {{ new Date().getFullYear() }} — Desenvolvido pelo <strong>DECEA</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheLayouSysagaFooter',
  components: {},
  data: () => ({
    links: [
      { icon: 'mdi-home', url: 'https://servicos.decea.gov.br/aga/', description: 'Portal Aeródromos' },
      { icon: 'mdi-help-circle-outline', url: 'https://servicos.decea.gov.br/aga/?i=sac', description: 'Fale Conosco' },
      { icon: 'mdi-note-text-outline', url: 'https://www.decea.gov.br/?i=paginas&p=politicas-de-uso-portal-aga', description: 'Política de Uso' },
    ],
  }),
  computed: {
    ...mapGetters([
      'footerShow',
    ]),
  },
  methods: {},
};
</script>

<style lang="scss">
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 890px) {
    // Para personalizar tela para impressão use print no lugar de screen
    .telaAcima890px {
      display: none;
    }
  }
  @media screen and (min-width: 890px){
    // Para personalizar tela para impressão use print no lugar de screen landscape
    .telaAbaixo890px {
      display: none;
    }
  }
  @media screen and (orientation: landscape) {
    // Para personalizar tela para impressão use print no lugar de screen
    .landscape {
      display: none;
    }
  }
  @media screen and (orientation: portrait) {
    // Para personalizar tela para impressão use print no lugar de screen
    .portrait {
      display: none;
    }
  }
</style>
