import components from '@/demo/locale/pt_BR/components';
import preanalise from '@/views/portal/locale/pt_BR/preanalise';
import inicio from '@/views/portal/locale/pt_BR/inicio';
import planos from '@/views/portal/locale/pt_BR/planos';
import documentos from '@/views/portal/locale/pt_BR/documentos';
import legislacao from '@/views/portal/locale/pt_BR/legislacao';
import duvidas from '@/views/portal/locale/pt_BR/duvidas';
import sysaga from '@/views/portal/locale/pt_BR/sysaga';
import tac from '@/views/portal/locale/pt_BR/tac';
import inscricao from '@/views/portal/locale/pt_BR/inscricao';
import alteracao from '@/views/portal/locale/pt_BR/alteracao';
import exploracao from '@/views/portal/locale/pt_BR/exploracao';
import planoDiretor from '@/views/portal/locale/pt_BR/planoDiretor';
import opea from '@/views/portal/locale/pt_BR/opea';
import aerodromo from '@/views/portal/locale/pt_BR/aerodromo';
import footer from '@/views/portal/locale/pt_BR/footer';
import logs from '@/views/sysaga/locale/pt_BR/logs';
import user from '@/views/sysaga/locale/pt_BR/user';
import denuncias from '@/views/sysaga/locale/pt_BR/denuncias';
import guideIndex from '../../views/common/locale/pt_BR/guideIndex';
import route from './route';

export default {
  route,
  inicio,
  preanalise,
  guideIndex,
  planos,
  documentos,
  legislacao,
  duvidas,
  sysaga,
  tac,
  inscricao,
  alteracao,
  exploracao,
  planoDiretor,
  opea,
  aerodromo,
  footer,
  logs,
  user,
  denuncias,

  components,
  toolbar: {
    appname: 'SYSAGA',
    settings: 'Configuração',
    profile: 'Dados pessoais',
    logout: 'Sair',
  },
  settings: {
    title: 'Configuração do Tema',
    default: 'Configuração Padrão',
    version: 'Version',
    position: 'Posição de elementos',
    theme: 'Tema',
    dark: 'Tema escuro',
    toolbarDense: 'Toolbar denso',
    navbarDense: 'Navbar denso',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Botão de Config',
    footer: 'Rodapé',
  },
  errors: {
    whoops: 'Ops',
    back: 'Tire-me daqui!',
    301: 'Movido Permanentemente',
    401: 'Não autorizado',
    403: 'Proibida',
    404: 'Não encontrado',
    500: 'Erro do Servidor Interno',
  },
  guide: {
    description: 'A página do guia é útil para algumas pessoas que entraram no projeto pela '
      + 'primeira vez. Você pode apresentar brevemente os recursos do projeto. A demonstração é baseada em',
    button: 'Mostrar Guia',
  },
  ui: {
    switch: 'Alternar idioma',
    theme: 'Alternar tema',
    success: 'Sucesso',
    error: 'Erro',
    warning: 'Atenção',
    info: 'Informação',
    primary: 'Primário',
    secondary: 'Secundário',
    default: 'Padrão',
    accent: 'Acentuado',
    firstName: 'Primeiro nome',
    lastName: 'Sobrenome',
    email: 'E-mail',
  },
  button: {
    search: 'Pesquisar',
    send: 'Enviar',
    resend: 'Reenviar',
    consult: 'Consultar',
    remove: 'Remover',
    delete: 'Apagar',
    clear: 'Limpar',
    create: 'Criar',
    update: 'Atualizar',
    request: 'Solicitar',
    next: 'Próximo',
    previous: 'Anterior',
    enter: 'Entrar',
    exit: 'Sair',
    close: 'Fechar',
    openProcess: 'Abrir Processo',
    singIn: 'Entrar',
    singUp: 'Criar conta',
    requestPreAnalisys: 'Solicitar Pré-Análise',
    helpCenter: 'Central de Ajuda',
    contactUs: 'Fale Conosco',
  },
  /**
   * TODO: Cavalcanti, favor colocar as linha abaixo no padrão
   * camelCase, em inglês, e traduzido, dentro da chave button
   * localizada logo acima. Caso já exista em button, remova a
   * linha e altere o caminho no componente.
  */
  clearFilters: 'Limpar filtros',
  fetchSameValueInMultipleFieldsValue: 'Busca',
  leiaMais: 'Leia mais',
  Filtros: 'Filtros',
  moreFilters: 'Mais filtros',
  Fechar: 'Fechar',
  columns: 'Colunas',
  lista_campos_busca: 'Busca nos seguintes campos',
  actions: 'Ações',
  Confirmo: 'Confirmo',
  closeBtnText: 'Fechar',
  nextBtnText: 'Próximo',
  prevBtnText: 'Anterior',
  doneBtnText: 'Finalizar',
};
