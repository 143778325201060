import components from '@/demo/locale/en_US/components';
import preanalise from '@/views/portal/locale/en_US/preanalise';
import inicio from '@/views/portal/locale/en_US/inicio';
import planos from '@/views/portal/locale/en_US/planos';
import documentos from '@/views/portal/locale/en_US/documentos';
import legislacao from '@/views/portal/locale/en_US/legislacao';
import duvidas from '@/views/portal/locale/en_US/duvidas';
import sysaga from '@/views/portal/locale/en_US/sysaga';
import tac from '@/views/portal/locale/en_US/tac';
import inscricao from '@/views/portal/locale/en_US/inscricao';
import alteracao from '@/views/portal/locale/en_US/alteracao';
import exploracao from '@/views/portal/locale/en_US/exploracao';
import planoDiretor from '@/views/portal/locale/en_US/planoDiretor';
import opea from '@/views/portal/locale/en_US/opea';
import aerodromo from '@/views/portal/locale/en_US/aerodromo';
import footer from '@/views/portal/locale/en_US/footer';
import logs from '@/views/sysaga/locale/en_US/logs';
import user from '@/views/sysaga/locale/en_US/user';
import denuncias from '@/views/sysaga/locale/en_US/denuncias';
import guideIndex from '@/views/common/locale/en_US/guideIndex';
import route from './route';

export default {
  route,
  inicio,
  preanalise,
  planos,
  denuncias,
  guideIndex,
  documentos,
  legislacao,
  duvidas,
  sysaga,
  tac,
  inscricao,
  alteracao,
  exploracao,
  planoDiretor,
  opea,
  aerodromo,
  footer,
  logs,
  user,

  components,
  toolbar: {
    appname: 'SYSAGA',
    settings: 'Settings',
    profile: 'Profile',
    logout: 'Logout',
  },
  settings: {
    title: 'Theme Settings',
    default: 'Set Default',
    version: 'Version',
    position: 'Position of elements',
    theme: 'Theme',
    dark: 'Dark theme',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer',
  },
  errors: {
    whoops: 'Whoops',
    back: 'Get me out of here!',
    301: 'Moved Permanently',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for '
      + 'the first time. You can briefly introduce the features of the project. Demo is based on',
    button: 'Show Guide',
  },
  ui: {
    switch: 'Switch Language',
    theme: 'Switch Theme',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    primary: 'Primary',
    secondary: 'Secondary',
    default: 'Default',
    accent: 'Accent',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
  },
  button: {
    search: 'Search',
    send: 'Send',
    resend: 'Resend',
    consult: 'Consult',
    remove: 'Remove',
    delete: 'Delete',
    clear: 'Clear',
    create: 'Create',
    update: 'Update',
    request: 'Request',
    next: 'Next',
    previous: 'Previous',
    enter: 'Enter',
    exit: 'Exit',
    close: 'Close',
    openProcess: 'Open Process',
    singIn: 'Enter',
    singUp: 'Register',
    requestPreAnalisys: 'Request Pre-Analysis',
    helpCenter: 'Help Center',
    contactUs: 'Contact Us',
  },
  /**
   * TODO: Cavalcanti, favor colocar as linha abaixo no padrão
   * camelCase, em inglês, e traduzido, dentro da chave button
   * localizada logo acima. Caso já exista em button, remova a
   * linha e altere o caminho no componente.
  */
  clearFilters: 'Limpar filtros',
  fetchSameValueInMultipleFieldsValue: 'Busca',
  leiaMais: 'Leia mais',
  Filtros: 'Filtros',
  moreFilters: 'Mais filtros',
  Fechar: 'Fechar',
  columns: 'Colunas',
  lista_campos_busca: 'Busca nos seguintes campos',
  actions: 'Actions',
  Confirmo: 'Confirmo',
  closeBtnText: 'Close',
  nextBtnText: 'Next',
  prevBtnText: 'Prev',
  doneBtnText: 'Done',
};
