export default {
  titulo: 'Planes de Zonas de Protección',
  subtitulo: '',
  texto:
  `
Acceda a los Planes de Zona de Protección publicados por
la DINAC seleccionando un aeródromo de su interés o una
minicipalid/distrito específico para averiguar qué Plan(es)
de Zona de Protección se utiliza(n) para disciplinar la ocupación
del terreno a fin de garantizar la seguridad y la regularidad de
las operaciones aéreas.
 Se puede buscar por ciudad o directamente por el nombre del aeródromo.
  `,
  tipos: {
    titulo: 'Tipos de Planes de Zona de Protección',
    pbzpa: {
      titulo: 'Plan básico de zona de protección de aeródromo (PBZPA)',
      texto:
      `
      Conjunto de superficies limitadoras de obstáculos que establecen
       restricciones al uso de propiedades alrededor de un aeródromo.
      `,
    },
    pbzph: {
      titulo: 'Plan Básico de Zona de Protección de Helipuerto (PBZPH)',
      texto:
      `
      Conjunto de superficies limitadoras de obstáculos que establecen
      restricciones al uso de propiedades alrededor de un helipuerto.
      `,
    },
    pzpana: {
      titulo: 'Plan de zona de protección de ayuda de navegación aérea (PZPANA)',
      texto:
      `
      Conjunto de superficies limitadoras de obstáculos que establecen restricciones
      al uso de propiedades alrededor de las ayudas, necesarias
      para el funcionamiento de los mismos,
      ubicadas dentro o fuera de los límites de la área de un cierto aeródromo.
      `,
    },
    pezpa: {
      titulo: 'Plan específico de zona de protección de aeródromo (PEZPA)',
      texto:
      `
      Documento de aplicación específica que establece las restricciones al
       uso de propiedades alrededor de ciertos aeródromos.
      `,
    },
    pzpreah: {
      titulo: 'Plan de protección para rutas especiales de aeronaves y helicópteros (PZPREAH)',
      texto:
        `
      Conjunto de superficies limitadoras de obstáculos que establecen restricciones
      al uso de propiedades alrededor de las rutas especiales de aviones y helicópteros.
      `,
    },
  },
  card: {
    titulo: 'Buscar planes',
    estado: 'Estado',
    municipio: 'Condado',
    oaci: 'Nombre del aeródromo o código OACI',
  },
  resultados_busca: 'Resultados de búsqueda para planes',
  resultado: {
    table_loading: 'Cargando por favor espere',
    table_headers: {
      nome_aerodromo: 'Nombre del aeródromo',
      data_assinatura: 'Data de Assinatura',
      oaci: 'OACI',
      tipo: 'Tipo',
      data: 'Fecha',
      portaria: 'Contacto',
      municipios_impactados: 'Municipios afectados',
      zonas_protecao: 'Zonas de protección',
    },
  },
};
