export default {
  titulo: 'Proyecto de aeródromo',
  subtitulo: '',
  texto: 'Seleccione el subtipo de proyecto:',

  cardAlteracao: {
    titulo: 'Cambio de registro',
    subtitulo: 'Archivo editable para procesos de interés público.',
  },
  cardExploracao: {
    titulo: 'Operación de aeródromos civiles públicos',
    subtitulo: 'Archivo editable para procesos de interés público.',
  },
  cardInscricao: {
    titulo: 'Suscripción',
    subtitulo: 'Archivo editable para procesos de interés público. (Anexos C5 y C6 de ICA 11-3)',
  },
  cardPlanoDiretor: {
    titulo: 'Plan maestro de aeropuerto',
    subtitulo: 'Archivo editable para procesos de interés público. (Anexos C5 y C6 de ICA 11-3)',
  },
};
