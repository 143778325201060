export default {
  titulo: 'Planos de Zona de Proteção',
  subtitulo: '',
  texto:
  `
  Acesse os planos de zona de proteção aprovados pelo DECEA, selecionando um
  aeródromo de interesse ou um município específico para saber qual(is)
  plano(s) de zona de proteção impõe(m) restrição(ões) ao munícipio.
  É possível buscar por cidade ou diretamente pelo nome do aerodrómo.
  Conheça os tipos de Planos de Zona de Proteção clicando abaixo.
  `,
  tipos: {
    titulo: 'Tipos de Planos de Zona de Proteção',
    pbzpa: {
      titulo: 'Plano Básico de Zona de Proteção de Aeródromo (PBZPA)',
      texto:
      `
      Conjunto de superfícies limitadoras de obstáculos que estabelece
      restrições ao aproveitamento das propriedades no entorno de um aeródromo.
      `,
    },
    pbzph: {
      titulo: 'Plano Básico de Zona de Proteção de Heliponto (PBZPH)',
      texto:
      `
      Conjunto de superfícies limitadoras de obstáculos que estabelece
      restrições ao aproveitamento das propriedades no entorno de um heliponto.
      `,
    },
    pzpana: {
      titulo: 'Plano de Zona de Proteção de Auxílios à Navegação Aérea (PZPANA)',
      texto:
      `
      Conjunto de superfícies limitadoras de obstáculos que estabelece restrições ao
      aproveitamento das propriedades no entorno dos auxílios, necessárias ao funcionamento
       dos mesmos, estando
      estes localizados dentro ou fora dos limites da área de um determinado aeródromo.
      `,
    },
    pezpa: {
      titulo: 'Plano Específico de Zona de Proteção de Aeródromo (PEZPA)',
      texto:
      `
      Documento de aplicação específica que estabelece as restrições ao
      aproveitamento das propriedades no entorno de determinados aeródromos.
      `,
    },
    pzpreah: {
      titulo: 'Plano de Zona de Proteção de Rotas Especiais de Aviões e Helicópteros (PZPREAH)',
      texto:
      `
      Conjunto de superfícies limitadoras de obstáculos que
       estabelece restrições ao aproveitamento
      das propriedades no entorno das rotas especiais de aviões e helicópteros.
      `,
    },
  },
  card: {
    titulo: 'Pesquisa de Planos de Zona de Proteção',
    estado: 'Estado',
    municipio: 'Município',
    oaci: 'Aeródromo',
  },
  resultados_busca: 'Resultado da busca de planos',
  resultado: {
    table_loading: 'Carregando... Aguarde',
    table_headers: {
      nome_aerodromo: 'Nome do Aeródromo',
      oaci: 'OACI',
      tipo: 'Tipo',
      data: 'Data',
      portaria: 'Portaria',
      municipios_impactados: 'Municípios impactados',
      zonas_protecao: 'Zonas de proteção',
    },
  },
};
