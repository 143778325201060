export default {
  subtitulo:
  `
  A exploração de aeródromos civis públicos, nos termos do Decreto nº 7.624,
  de 22 de novembro de 2011, e do Decreto nº 7.871, de 21 de dezembro de 2012,
  dependem de análise do COMAER quanto aos temas de sua competência, exigida
  como etapa constituinte do processo de obtenção do instrumento de outorga
  para exploração de aeródromos civis públicos coordenado pela SAC/MInfra.
  `,
  texto:
  `
  A deliberação favorável do COMAER para um processo de exploração de aeródromo
  civil público terá validade de cinco anos para efeito de apresentação do
  processo de inscrição ou alteração no cadastro, conforme o caso, por parte
  interessado.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
