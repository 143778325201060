export default {
  titulo: 'Projeto de Objeto Projetado no Espaço Aéreo (OPEA)',
  subtitulo:
  `
  Objeto, de qualquer natureza, temporária ou permanente, fixa ou móvel,
  sujeito à análise sob os aspectos de uso do espaço aéreo nacional,
  utilizando-se os parâmetros estabelecidos na ICA 11-408 e em
  norma complementar do COMAER.
  `,
  texto:
  `
  A pré-análise consiste em uma ferramenta de análise do Sistema de
  Gerenciamento de Processos AGA (SYSAGA), onde o
  interessado em construir edificações e que estariam dispensados
  de consultar o Comando da Aeronáutica (COMAER), submete as
  informações básicas de coordenadas, altitude da base e altura
  do objeto. A partir desta pré-análise, o interessado poderá
  receber um documento de inexigibilidade do COMAER ou um aviso
  da necessidade de se abrir um processo. Acesse o SYSAGA para
  iniciar uma pré-análise ou abrir um processo.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
