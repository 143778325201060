<template>
  <v-footer
    padless
    inset
    class="footer-main"
  >
    <v-container
      class="footer-main--primary"
      fluid
    >
      <v-row>
        <v-col />
        <v-col class="pa-10">
          <h2 class="text-h3 font-weight-bold white--text mb-4">
            {{ $t('footer.duvidas_mais_comuns') }}
          </h2>
          <p class="text-subtitle-1 white--text">
            {{ $t('footer.p1') }} <br>
            {{ $t('footer.p2') }}
          </p>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <!--          <div class="text-center">-->
          <!--            <v-dialog-->
          <!--              v-model="dialog"-->
          <!--              width="500"-->
          <!--            >-->
          <!--                <template v-slot:activator="{on, attrs}">-->
          <!--                  <v-div style="padding: 20px !important;" >-->
          <!--                    <v-btn-->
          <!--                      depressed-->
          <!--                      color="white"-->
          <!--                      v-bind="attrs"-->
          <!--                      v-on="on"-->
          <!--                    >-->
          <!--                      {{ $t('footer.cadastrar') }}-->
          <!--                    </v-btn>-->
          <!--                  </v-div>-->
          <!--                </template>-->

          <!--              <v-card>-->
          <!--                <v-card-title class="headline grey lighten-2">-->
          <!--                  {{ $t('footer.assinar') }}-->
          <!--                </v-card-title>-->
          <!--                <v-col-->
          <!--                  cols="12"-->
          <!--                  sm="6"-->
          <!--                  md="4"-->
          <!--                />-->
          <!--                <v-col cols="12">-->
          <!--                  <v-text-field-->
          <!--                    label="Email*"-->
          <!--                    required-->
          <!--                  />-->
          <!--                </v-col>-->

          <!--                <v-divider/>-->

          <!--                <v-card-actions>-->
          <!--                  <v-spacer/>-->
          <!--                  <v-btn-->
          <!--                    color="blue darken-1"-->
          <!--                    text-->
          <!--                    @click="dialog = false"-->
          <!--                  >-->
          <!--                    {{ 'Cancelar' }}-->
          <!--                  </v-btn>-->
          <!--                  <v-btn-->
          <!--                    color="primary"-->
          <!--                    text-->
          <!--                    @click="dialog = false"-->
          <!--                  >-->
          <!--                    {{ 'Cadastrar' }}-->
          <!--                  </v-btn>-->
          <!--                </v-card-actions>-->
          <!--              </v-card>-->
          <!--            </v-dialog>-->
          <!--          </div>-->
          <v-btn
            depressed
            color="white"
            target="_blank"
            href="https://ajuda.decea.mil.br/artigo-categoria/sysaga/"
          >
            {{ $t('footer.tenho_duvidas') }}
          </v-btn>
          <!-- <v-btn
            outlined
            color="white"
            href="/atendimento"
          >
            {{ $t('footer.entrar_em_contato') }}
          </v-btn> -->
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="footer-main--secondary"
      fluid
    >
      <v-container>
        <v-row align="center">
          <div class="d-flex align-center">
            <a
              href="https://www.decea.gov.br/"
              target="_blank"
            >
              <v-img
                src="/img/logos/decea.png"
              />
            </a>
            <a
              href="https://www.fab.mil.br/"
              class="ml-10"
            >
              <v-img
                src="/img/logos/sabre-alado-comando-da-aeronautica.png"
              />
            </a>
          </div>
          <v-spacer />
          <div class="d-flex development">
            Desenvolvido pelo DECEA
          </div>
        </v-row>
      </v-container>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'TheLayoutPortalFooter',
  data: () => ({
    dialog: false,
  }),
};
</script>

<style lang="scss" scoped>
  .footer-main {
    &--primary {
      background: url('/static/bg/bg_footer.png') no-repeat left center #089BCE;

      .v-btn {
        color: #089BCE;

        & + .v-btn {
          margin-left: 20px;
        }
      }
    }

    &--secondary {
      background-color: #3D4143;
      color: #ccc;

      .development {
        font-size: 12px;
      }
    }
  }
</style>
