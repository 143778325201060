export default {
  titulo: 'Documentos Auxiliares',
  subtitulo: '',
  texto: 'Proporcionamos una lista de documentos que lo ayudarán en el proceso:',
  opea: {
    card: {
      assunto: 'Objetos Proyectados en el Espacio Aéreo',
      explicacao: `Archivos necesarios para la apertura de procesos relacionados con Objetos (OPEA)
      , como la construcción de edificios, complejos habitacionales, loteos,
       puentes, viaductos, globos cautivos, rellenos sanitarios, estación de servicio,
        mástiles, torres, parques eólicos, líneas de transmisión, antenas y proyectores
         láser, entre otros.`,
    },
  },
  aerodromo: {
    card: {
      assunto: 'Aeródromos',
      explicacao: `Archivos necesarios para la apertura de procesos relacionados con Aeródromos,
       como Inscripción, Alteración, PDIR y Exploración `,
    },
  },
  apoio: {
    card: {
      assunto: 'Soporte',
      explicacao: 'Archivo de orientaciones, tutoriales y links externos al COMAER',
    },
  },
};
