export default {
  titulo: 'Projeto de aeródromo',
  subtitulo: '',
  texto: 'Selecione o sub-tipo do projeto:',

  cardAlteracao: {
    titulo: 'ALTERAÇÃO DE CADASTRO',
    subtitulo: 'Arquivo editável para processos de interesse público',
  },
  cardExploracao: {
    titulo: 'EXPLORAÇÃO DE AERÓDROMOS CIVIS PÚBLICOS',
    subtitulo: 'Arquivo editável para processos de interesse público',
  },
  cardInscricao: {
    titulo: 'INSCRIÇÃO DO CADASTRO',
    subtitulo: 'Arquivo editável para processos de interesse público (Anexos C5 e C6 à ICA 11-3)',
  },
  cardPlanoDiretor: {
    titulo: 'PLANO DIRETOR AEROPORTUÁRIO',
    subtitulo: 'Arquivo editável para processos de interesse público (Anexos C5 e C6 à ICA 11-3)',
  },
};
