export default {
  title: 'Usuários',
  table: {
    search: 'Buscar',
    blockedRequestPreAnalysis: 'Bloqueio solicitação pré-análise até',
    name: 'Nome',
    groups: 'Grupos',
    actions: 'Ações',
  },
};
