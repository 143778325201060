export default {
  subtitulo:
  `
  Documento preparado por un Responsable Técnico calificado y presentado
  por el operador del aeródromo, que cambia la configuración de la
  infraestructura del aeropuerto, incluidas las características
  físicas y / u operativas de los aeródromos o helipuertos.
  `,
  texto: '',
  cardDocumentacao: {
    titulo: 'Documentación relacionada',
    texto: '',
  },
};
