import Vue from 'vue';
import Vuex from 'vuex';
import syncStorage from './plugins/syncStorage';
import permission from './modules/permission';
import settings from './modules/settings';
import user from './modules/user';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    permission,
    settings,
    user,
  },

  plugins: [
    // Serve para resgatar dados do usuário quando houver refresh ou abertura de página em nova aba.
    syncStorage({}),
  ],

  state: {
    planos: [],
    tacs: [],
    tablePlanosLoading: false,
    tableTacsLoading: false,
    permissionRoutes: router.options.routes,
  },
  mutations: {
    setTacs: (state, tacs) => {
      state.tacs = tacs;
      state.tableTacsLoading = false;
    },
    onLoadTableLoadTacs: (state, loading) => {
      console.log(loading);
      state.tableTacsLoading = true;
    },
    setPlanos: (state, planos) => {
      state.planos = planos;
      state.tablePlanosLoading = false;
    },
    onLoadTableLoadPlanos: (state, loading) => {
      console.log(loading);
      state.tablePlanosLoading = true;
    },
  },
  actions: {
    TacsSet: async (context, tacs) => {
      context.commit('setTacs', tacs);
    },
    LoadTableTacs: async (context, loading) => {
      context.commit('onLoadTableLoadTacs', loading);
    },
    PlanosSet: async (context, planos) => {
      context.commit('setPlanos', planos);
    },
    LoadTablePlanos: async (context, loading) => {
      context.commit('onLoadTableLoadPlanos', loading);
    },
  },
  getters: {
    tacsShow: (state) => state.tacs,
    tableTacsLoading: (state) => state.tableTacsLoading,
    planosShow: (state) => state.planos,
    tablePlanosLoading: (state) => state.tablePlanosLoading,
    routes: (state) => state.permissionRoutes,
  },
});
