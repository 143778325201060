export default {
  titulo: 'What is Pre-Analysis?',
  texto:
  `
  Pre-analysis consists of a check based on the criteria for
  request for analysis of object projected in airspace that does not
  are contemplated in Chapter VII of Ordinance 957 / GC3, of 09
  July 2015. With the ease of pre-analysis, the applicant is sufficient
  submit basic coordinate information, base altitude,
  height of the object (which will allow you to reach the top altitude,
  positioned in relation to a basic plan of an existing aerodrome
  surrounding the project), to find out if he would be able or not to
  build in that area. With this, the tool avoids opening
  unnecessary processes, as it generates an unenforceability document,
  if the result of the analysis is positive. If not, the
  applicant will receive information that it will need to file a case
  through the Process Management System of the AGA Area (SYSAGA).
  From 10/01/2018 the pre-analysis became an opening stage
  in SYSAGA.
  `,
  consulta: {
    titulo: 'Consultation of PRE-ANALYSIS requests:',
    label: 'Protocol number',
  },
};
