<template>
  <v-app-bar
    app
    dark
    hide-on-scroll
    height="130"
  >
    <v-row
      no-gutters
      class=" my-0 py-0"
    >
      <!-- Inicio Barra DECEA  -->
      <v-col cols="12">
        <app-toolbar-decea-content />
      </v-col>
      <!-- Fim Barra DECEA  -->
      <!-- Inicio Navbar PORTAL  -->
      <v-col
        cols="12"
        class="toolbar-aga d-flex align-end my-0 py-0"
      >
        <v-row
          align="center"
          justify="space-between"
          class="flex-nowrap pl-5 pl-sm-10 pb-0 pr-10"
        >
          <v-app-bar-nav-icon
            class="hidden-lg-and-up blue darken-4"
            small
            @click.stop="toggleNavbar"
          >
            <v-icon small>
              {{ toggleNavbarIcon }}
            </v-icon>
          </v-app-bar-nav-icon>
          <img
            src="/img/logos/logo-aga.png"
            alt="SysAGA"
            height="40"
            width="200"
            class="hidden-sm-and-down"
          >
          <the-layout-portal-toolbar-tab
            class="hidden-md-and-down"
          />
          <!--<localization />-->
        </v-row>
      </v-col>
      <!-- Fim Navbar PORTAL  -->
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLayoutPortalToolbarTab from './TheLayoutPortalToolbarTab.vue';
import AppToolbarDeceaContent from '../../common/layout/AppToolbarDeceaContent.vue';
// import Localization from '../../common/widget/AppLocalization.vue';

export default {
  name: 'TheLayoutPortalToolbar',
  components: {
    TheLayoutPortalToolbarTab,
    AppToolbarDeceaContent,
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'navbarShow',
    ]),
    toggleNavbarIcon() {
      return this.navbarShow ? 'mdi-format-indent-decrease' : 'mdi-format-indent-increase';
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch('NavbarToggle');
    },
  },
};
</script>

<style lang="scss" scoped>
  .toolbar-aga {
    background-color: #F8FAFA;
    height: 57px;
    border-top: 4px solid #089BCE;
    position: relative;
  }
  @media screen and (max-width: 890px) {
    // Para personalizar tela para impressão use print no lugar de screen
    .telaAcima890px {
        display: none;
    }
  }
  @media screen and (min-width: 890px){
    // Para personalizar tela para impressão use print no lugar de screen landscape
    .telaAbaixo890px {
      display: none;
    }
  }
  @media screen and (orientation: landscape) {
    // Para personalizar tela para impressão use print no lugar de screen
    .landscape {
      display: none;
    }
  }
  @media screen and (orientation: portrait) {
    // Para personalizar tela para impressão use print no lugar de screen
    .portrait {
      display: none;
    }
  }
</style>
