export default {
  titulo: 'Denúncias',
  subtitulo: '',
  texto:
  `
  Acesse texto explicação denuncias
  Lorem Ipsum is simply dummy text of the printing
  and typesetting industry. Lorem Ipsum has been
  the industry's standard dummy text ever since the
  1500s, when an unknown printer took a galley of
  type and scrambled it to make a type specimen book.
   It has survived not only five centuries, but also
   the leap into electronic typesetting, remaining
   essentially unchanged. It was popularised in the
   1960s with the release of Letraset sheets containing
    Lorem Ipsum passages, and more recently with desktop
    publishing software
  like Aldus PageMaker including versions of Lorem Ipsum.
  `,
  card: {
    titulo: 'Buscar denúncias',
    estado: 'Estado',
    municipio: 'Município',
    oaci: 'Aeródromo,CIAD ou OACI',
  },
  resultados_busca: 'Resultado da busca de denúncias',
  resultado: {
    table_loading: 'Carregando... Aguarde',
    table_headers: {
      regional: 'Regional',
      regional_explicacao: `No Brasil a analise de processos de divide ' +
        'em 5 localidades, chamadas de regionais: CINDACTA 1, CINDACTA 2, CINDACTA 3,
         CINDACTA 4 e SRPV-SP`,
      denuncia: 'Denúncia',
      denuncia_explicacao: 'Resumo da denúncia',
      numeroDenuncia: 'Nº Denúncia',
      numeroDenuncia_explicacao: 'Número sequencia de controle de denúnicas da AGA',
      nup: 'NUP',
      nup_explicacao: 'Número do processo do COMAER',
      created_at: 'Criação',
      created_at_explicacao: `Data de criação no banco de dados, tem dois filtros;
      inicial: data de início da busca,
      final: data final da busca
      `,
      conteudo: 'Conteúdo',
      processo_tipo: 'Tipo processo',
    },
  },
};
