export default {
  titulo: 'Termo de ajustamento de conduta (TAC)',
  subtitulo: '',
  texto:
  `
  O Termo de Ajustamento de Conduta é um documento celebrado entre o Operador
  de Aeródromo ou seu representante designado e o DECEA, para aqueles aeródromos
  que foram fechados, ou tenham previsão de fechamento,
   devido a não apresentação do Plano Básico de Zona de Proteção.
  `,
  ajuda: 'Como solicitar o Termo de Ajustamento de Conduta (TAC)',
  link_ajuda: 'https://ajuda.decea.mil.br/base-de-conhecimento/como-solicitar-o-termo-de-ajustamento-de-conduta-tac-no-portal-aga/',
  card: {
    titulo: 'Buscar termos',
    estado: 'Estado',
    municipio: 'Município',
    oaci: 'Nome do aeródromo ou código OACI',
  },
  resultados_busca: 'Resultado da busca de TAC',
  resultado: {
    table_loading: 'Carregando... Aguarde',
    table_headers: {
      nome_aerodromo: 'Nome do Aeródromo',
      data_assinatura: 'Data de Assinatura',
      dou: 'DOU',
      oaci: 'OACI',
      tipo: 'Tipo',
      data: 'Data',
      validade: 'Validade',
    },
  },
};
