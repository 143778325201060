export default {
  subtitulo:
  `
  The operation of public civil aerodromes, pursuant to Decree No. 7,624,
  of November 22, 2011, and of Decree No. 7,871, of December 21, 2012,
  depend on COMAER's analysis of the topics within its competence, required
  as a constituent step in the process of obtaining the grant instrument
  for the operation of public civil aerodromes coordinated by SAC/MInfra.
  `,
  texto:
  `
  COMAER's favorable decision for an aerodrome exploration process
  public civil service will be valid for five years for the purpose of presenting the
  registration process or change in the registration, as the case may be, by
  interested.
  `,
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
