export default {
  subtitulo:
  `
  Documento elaborado pelo operador do aeródromo, que estabelece o planejamento
  para a expansão da infraestrutura aeroportuária em consonância com a
  regulamentação de segurança operacional expedida pela ANAC. Este documento,
  para os fins da ICA 11-3, deverá ser elaborado por responsáveis técnicos
  devidamente qualificados.
  `,
  texto: '',
  cardDocumentacao: {
    titulo: 'Documentação relacionada',
    texto: '',
  },
};
