/**
 * Aqui estão os títulos das rotas que serão exibidos no SideBar e BreadCrumb.
 * Ao adicionar uma rota em router/modules/sysaga.js,
 * insira na chave meta da rota, o nome da chave inserida nesse arquivo, em inglês.
 * Exemplo:
 * Chave inserida nesse arquivo: dashboard
 * Chave meta da rota: meta: { title: 'route.dashboard' },
*/
export default {
  portal: {
    atendimento: {
      title: 'Asistencia',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    opea: {
      title: 'Objeto Proyectado en Espacio Aéreo',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    aerodromo: {
      title: 'Aeródromo',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    alteracao: {
      title: 'Cambio de registro',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    inscricao: {
      title: 'Suscripción',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    exploracao: {
      title: 'Exploración',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    planoDiretor: {
      title: 'Plan Maestro',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    inicio: {
      title: 'Inicio',
      description: ' Es Pagina inicial é a pagina do inicio...',
    },
    planos: {
      title: 'Planes',
      description: 'Pagina planos é a pagina do sobre...',
    },
    documentos: {
      title: 'Descargas',
      description: 'Pagina documentos é a pagina do sobre...',
    },
    legislacao: {
      title: 'Legislación',
      description: 'Pagina legislacao é a pagina do sobre...',
    },
    duvidas: {
      title: 'Dudas',
      description: 'Pagina duvidas é a pagina do sobre...',
    },
    preanalise: {
      title: 'Pré-análise',
      description: 'Pagina Pré-análise...',
    },
    sysaga: {
      title: 'SYSAGA',
      description: 'Pagina SYSAGA é a pagina do sobre...',
    },
    tac: {
      title: 'Término de Ajuste de Conducta',
      description: 'Término de ajuste de conducta',
    },
  },
  dashboard: 'Tablero',
  complaints: 'Quejas',
  fluxograma: 'Fluxograma',
  user: 'Los usuarios',
  // introduction: 'Introduction',
  documentation: 'Documentación',
  guide: 'Guía',
  roadmap: 'Mapa vial',
  i18n: 'i18n',
  theme: 'Tema',
  // pagePermission: 'Page Permission',
  // rolePermission: 'Role Permission',
  permissions: 'Permisos',
  permission: {
    admin: 'Administrador',
    editor: 'Editor',
    visitor: 'Visitante',
  },
  components: 'Componentes',
  component: {
    avatarUpload: 'Avatar Upload',
    backToTop: 'Back To Top',
    countTo: 'Count To',
    dragKanban: 'Drag Kanban',
    dropzone: 'Dropzone',
    jsonEditor: 'Json Editor',
    markdown: 'Markdown',
    splitPane: 'Split Pane',
    sticky: 'Sticky',
    tinymce: 'Tinymce',
  },
  vuetify: 'Vuetify UI',
  vuetifyComponents: {
    components: 'Components',
    alert: 'Alert',
    avatar: 'Avatars',
    badge: 'Badges',
    buttons: 'Buttons',
    calendar: 'Calendar',
    cards: 'Cards',
    carousels: 'Carousels',
    chip: 'Chip',
    colors: 'Colors',
    datepicker: 'Datepicker',
    dialogs: 'Dialogs',
    grid: 'Grid',
    icon: 'Icons',
    pagination: 'Pagination',
    parallax: 'Parallax',
    progress: 'Progress',
    slider: 'Slider',
    snackbar: 'Snackbar',
    tables: 'Tables',
    timepicker: 'Timepicker',
    tooltip: 'Tooltip',
    typography: 'Typography',
  },
  errors: 'Errors',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: 'Charts',
  chart: {
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
  },
  nested: {
    nested: 'Nested',
    nested1: 'Level 1',
    nested2: 'Level 2',
    nested3: 'Level 3',
  },
  clipboard: 'Clipboard',
  externalLink: 'External Link',
};
