export default {
  Protocolo: 'Protocolo',
  titulo: 'Pré-análise',
  texto:
  `
  Consulta de Pré-análise a partir do protocolo
  `,
  consulta: {
    titulo: 'Consulta de solicitações de PRÉ-ANÁLISE:',
    label: 'Número de protocolo',
  },
};
