<template>
  <div class="container--fluid fill-height">
    <the-layout-portal-toolbar />
    <div class="v-application--wrap">
      <the-layout-portal-drawer v-if="$vuetify.breakpoint.mdAndDown" />
      <the-layout-portal-content />
      <the-layout-portal-footer />
    </div>
  </div>
</template>

<script>
import TheLayoutPortalToolbar from './TheLayoutPortalToolbar.vue';
import TheLayoutPortalDrawer from './TheLayoutPortalDrawer.vue';
import TheLayoutPortalContent from './TheLayoutPortalContent.vue';
import TheLayoutPortalFooter from './TheLayoutPortalFooter.vue';

export default {
  name: 'TheLayoutPortal',
  components: {
    TheLayoutPortalToolbar,
    TheLayoutPortalDrawer,
    TheLayoutPortalContent,
    TheLayoutPortalFooter,
  },
  data: () => ({}),
};
</script>
