/**
 * Aqui estão os títulos das rotas que serão exibidos no SideBar e BreadCrumb.
 * Ao adicionar uma rota em router/modules/sysaga.js,
 * insira na chave meta da rota, o nome da chave inserida nesse arquivo, em inglês.
 * Exemplo:
 * Chave inserida nesse arquivo: dashboard
 * Chave meta da rota: meta: { title: 'route.dashboard' },
*/
export default {
  portal: {
    atendimento: {
      title: 'Attendance',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    opea: {
      title: 'Object Projected in Airspace',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    aerodromo: {
      title: 'Aerodrome',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    alteracao: {
      title: 'Registration Change',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    inscricao: {
      title: 'Subscription',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    exploracao: {
      title: 'Exploration',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    planoDiretor: {
      title: 'Airport Master Plan',
      description: 'Pagina inicial é a pagina do inicio...',
    },
    inicio: {
      title: 'Start',
      description: ' EN Pagina inicial é a pagina do inicio...',
    },
    planos: {
      title: 'Plans',
      description: 'Pagina planos é a pagina do sobre...',
    },
    documentos: {
      title: 'Downloads',
      description: 'Pagina documents é a pagina do sobre...',
    },
    legislacao: {
      title: 'Legislation',
      description: 'Pagina legislacao é a pagina do sobre...',
    },
    duvidas: {
      title: 'Doubts',
      description: 'Pagina duvidas é a pagina do sobre...',
    },
    preanalise: {
      title: 'Pré-análise',
      description: 'Pagina Pré-análise...',
    },
    sysaga: {
      title: 'SYSAGA',
      description: 'Pagina SYSAGA é a pagina do sobre...',
    },
    tac: {
      title: 'Conduct Adjustment Term',
      description: 'Conduct Adjustment Term',
    },
  },
  dashboard: 'Dashboard',
  complaints: 'Complaints',
  fluxograma: 'Fluxograma',
  user: 'Users',
  // introduction: 'Introduction',
  documentation: 'Documentation',
  guide: 'Guide',
  roadmap: 'Roadmap',
  i18n: 'i18n',
  theme: 'Theme',
  // pagePermission: 'Page Permission',
  // rolePermission: 'Role Permission',
  permissions: 'Permission',
  permission: {
    admin: 'Admin',
    editor: 'Editor',
    visitor: 'Visitor',
  },
  components: 'Components',
  component: {
    avatarUpload: 'Avatar Upload',
    backToTop: 'Back To Top',
    countTo: 'Count To',
    dragKanban: 'Drag Kanban',
    dropzone: 'Dropzone',
    jsonEditor: 'Json Editor',
    markdown: 'Markdown',
    splitPane: 'Split Pane',
    sticky: 'Sticky',
    tinymce: 'Tinymce',
  },
  vuetify: 'Vuetify UI',
  vuetifyComponents: {
    components: 'Components',
    alert: 'Alert',
    avatar: 'Avatars',
    badge: 'Badges',
    buttons: 'Buttons',
    calendar: 'Calendar',
    cards: 'Cards',
    carousels: 'Carousels',
    chip: 'Chip',
    colors: 'Colors',
    datepicker: 'Datepicker',
    dialogs: 'Dialogs',
    grid: 'Grid',
    icon: 'Icons',
    pagination: 'Pagination',
    parallax: 'Parallax',
    progress: 'Progress',
    slider: 'Slider',
    snackbar: 'Snackbar',
    tables: 'Tables',
    timepicker: 'Timepicker',
    tooltip: 'Tooltip',
    typography: 'Typography',
  },
  errors: 'Errors',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: 'Charts',
  chart: {
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
  },
  nested: {
    nested: 'Nested',
    nested1: 'Level 1',
    nested2: 'Level 2',
    nested3: 'Level 3',
  },
  clipboard: 'Clipboard',
  externalLink: 'External Link',
};
