/** When your routing table is too long, you can split it into small modules */

import TheLayoutSysaga from '@/views/sysaga/layout/TheLayoutSysaga.vue';

const permissionRouter = {
  path: '/permission',
  component: TheLayoutSysaga,
  redirect: '#',
  name: 'Permission',
  meta: {
    title: 'route.permissions',
    icon: 'mdi-account-group',
    roles: ['Administrador', 'Interno', 'Externo'],
    requiresAuth: true,
  },
  children: [
    {
      path: 'Administrador',
      component: () => import('@/views/sysaga/pages/Permission/Admin.vue'),
      name: 'PermissionAdmin',
      meta: {
        title: 'route.permission.admin', roles: ['Administrador'], noCache: true, requiresAuth: true,
      },
    },
    {
      path: 'Interno',
      component: () => import('@/views/sysaga/pages/Permission/Editor.vue'),
      name: 'PermissionEditor',
      meta: {
        title: 'route.permission.editor', roles: ['Interno'], noCache: true, requiresAuth: true,
      },
    },
    {
      path: 'Externo',
      component: () => import('@/views/sysaga/pages/Permission/Visitor.vue'),
      name: 'PermissionVisitor',
      meta: {
        title: 'route.permission.visitor', roles: ['Externo'], noCache: true, requiresAuth: true,
      },
    },
  ],
};

export default permissionRouter;
