export default {
  titulo: 'Auxiliary Documents',
  subtitulo: '',
  texto: 'We provide a list of documents that will assist you in the process:',
  opea: {
    card: {
      assunto: 'Objects Projected in Airspace',
      explicacao: `Necessary files for opening processes related to Objects (OPEA),
         such as construction of buildings, housing complexes and developments, bridges,
         overpasses, captive balloons, landfills, gas stations, masts, towers, wind farms,
         transmission lines, antennas, laser beam projectors, among others.`,
    },
  },
  aerodromo: {
    card: {
      assunto: 'Aerodromes',
      explicacao: `Files necessary for opening processes related to Aerodromes,
      such as Registration, Change, PDIR and Operation.`,
    },
  },
  apoio: {
    card: {
      assunto: 'Support',
      explicacao: 'Guidance files, tutorials and external links to COMAER.',
    },
  },
};
