<template>
  <div class="v-application--wrap">
    <the-layout-sysaga-drawer />
    <the-layout-sysaga-toolbar />
    <the-layout-sysaga-content />
    <the-layout-sysaga-fab />
    <the-layout-sysaga-to-top-fab />
    <the-layout-sysaga-footer />
  </div>
</template>

<script>
import TheLayoutSysagaDrawer from './TheLayoutSysagaDrawer.vue';
import TheLayoutSysagaToolbar from './TheLayoutSysagaToolbar.vue';
import TheLayoutSysagaContent from './TheLayoutSysagaContent.vue';
import TheLayoutSysagaFab from './TheLayoutSysagaFab.vue';
import TheLayoutSysagaToTopFab from './TheLayoutSysagaToTopFab.vue';
import TheLayoutSysagaFooter from './TheLayoutSysagaFooter.vue';

export default {
  name: 'TheLayoutSysaga',
  components: {
    TheLayoutSysagaDrawer,
    TheLayoutSysagaToolbar,
    TheLayoutSysagaContent,
    TheLayoutSysagaFab,
    TheLayoutSysagaToTopFab,
    TheLayoutSysagaFooter,
  },
};
</script>
