export default {
  titulo: 'Términos de Ajustamiento de Conducta (TAC)',
  subtitulo: '',
  texto:
  `
  El Término de Ajustamiento de Conducta es un documento firmado entre
  el Operador del Aeródromo o su representante designado y el DECEA para
  aquellos aeródromos que se cerraron, o cuyo cierre está previsto,
  debido a la falta de presentación del Plan Básico de Zona de Protección.
  `,
  card: {
    titulo: 'Términos de búsqueda',
    estado: 'Departamento',
    municipio: 'Municipio',
    oaci: 'Buscar Aeródromo (Nombre o Código OACI (ICAO))',
  },
  resultados_busca: 'Resultados de búsqueda para TAC',
  resultado: {
    table_loading: 'Cargando por favor espere',
    table_headers: {
      nome_aerodromo: 'Nombre del aeródromo',
      data_assinatura: 'Data de Assinatura',
      dou: 'DOU',
      oaci: 'OACI',
      tipo: 'Tipo',
      data: 'Fecha',
      validade: 'Duracion',
    },
  },
};
