export default {
  calendarTitle: 'FECHAS CON OCURRENCIA DE REGISTROS',
  filterTitle: 'Filtro por categoría:',
  buttonClearFilter: 'Eliminar Filtro',
  table: {
    search: 'Buscar',
    timestamp: 'Fecha',
    type: 'Tipo',
    message: 'Mensaje',
  },
};
