import axios from 'axios';
import router from '@/router';
import CryptoJS from 'crypto-js';

const user = {
  state: {
    id: '',
    name: '',
    email: '',
    cpf: '',
    roles: [],
    permissions: [],
    user: '',
    avatar: '',
    status: '',
  },

  getters: {
    id: (state) => state.id,
    name: (state) => state.name,
    email: (state) => state.email,
    cpf: (state) => state.cpf,
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
    user: (state) => state.user,
    avatar: (state) => state.avatar,
    status: (state) => state.status,
  },

  mutations: {
    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.id = '';
        state.name = '';
        state.email = '';
        state.cpf = '';
        state.roles = [];
        state.permissions = [];
        state.user = '';
        state.avatar = '';
      } else {
        state.id = payload.id || state.id;
        state.name = payload.name || state.name;
        state.email = payload.email || state.email;
        state.cpf = payload.cpf || state.cpf;
        state.roles = payload.roles || state.roles;
        state.permissions = payload.permissions || state.permissions;
        state.user = payload || state.user;
        state.avatar = payload.avatar || state.avatar;
      }
    },
  },

  actions: {
    SetGetUserInfoBackend: async ({ commit, dispatch }) => {
      try {
        await axios.get(`${process.env.VUE_APP_URL_API}/keycloak/user_info`, { headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
          .then((response) => {
            console.log(response.data);
            // Encriptar user_info no localStorage
            const encryptedAESUser = CryptoJS.AES.encrypt(
              JSON.stringify(response.data.user_info_backend),
              router.app.$keycloak.subject,
            );
            localStorage.setItem('vva_user', encryptedAESUser);
            commit('SET_USER_INFO', response.data.user_info_backend);
            dispatch('GenerateRoutes', response.data.user_info_backend);
          }).catch((error) => {
            console.log('[vuex.user] GetUserInfoByKeycloak - Backend unavailable', error);
            /** TODO: Se houver erro no Back o usuário não terá suas informações em AppProfile,
           * sugiro inserir logout(). O código abaixo traz informações do Keycloak, mas sem o Back
           * não tem sentido continuar navegando no App sem poder fazer consultas, etc. */
            commit('SET_USER_INFO', {
              name: router.app.$keycloak.fullName,
              email: router.app.$keycloak.userName,
              cpf: router.app.$keycloak.idTokenParsed.cpf,
              roles: router.app.$keycloak.resourceAccess.sysaga.roles,
            });
            dispatch('GenerateRoutes', { roles: router.app.$keycloak.resourceAccess.sysaga.roles });
            /** TODO: Descomentar as 02 linhas abaixo quando comentar os 02 comandos acima.
             * É um paleativo para quando não houver backend. É o caso da equipe de estilo */
            // router.push({ path: '/erro/500', replace: true, query: { noGoBack: true } });
            // setTimeout(() => { dispatch('LogoutByKeycloak'); }, 3000);
          });
      } catch (err) {
        console.warn('[vuex.user] GetUserInfoByKeycloak try-catch', err);
      }
    },

    LogoutByKeycloak: async ({ commit }) => {
      commit('SET_USER_INFO', { logout: true });
      localStorage.removeItem('vva_user');
      router.app.$keycloak.logoutFn();
    },

    // Descomente se for utilizar Info e Roles do Keycloak
    // SetUserInfoKeycloak: async ({ commit, dispatch }, payload) => {
    //   commit('SET_USER_INFO', payload);
    //   dispatch('GenerateRoutes', payload);
    // },

    // Sem uso. Busca de dados no Back via JWT
    // GetUserInfoByJwt: async ({ commit, dispatch }, payload) => {
    //   try {
    //     await axios.post(`${process.env.VUE_APP_URL_API}/auth/login`, payload)
    //       .then((response) => {
    //         console.log(response);
    //         commit('SET_TOKEN', { token: response.data.token });
    //         commit('SET_USER_INFO', response.data.usuario);
    //         dispatch('GenerateRoutes', response.data.usuario);
    //       }).catch((error) => {
    //         console.log(error);
    //         // router.push(`/singin?error=${error}`);
    //       });
    //   } catch (err) {
    //     console.warn('[vuex.user] GetUserInfoByJwt', err);
    //   }
    // },

    /* Login usado antes do Keycloak. Envia email e senha, recebe token JWT com dados do usuário e
    redireciona pra rota /dashboard se estiver com email verificado */
    // LoginByEmail: async ({ commit, dispatch }, payload) => {
    //   try {
    //     await axios.post(`${process.env.VUE_APP_URL_API}/auth/login`, payload)
    //       .then((response) => {
    //         commit('SET_TOKEN', { token: response.data.token });
    //         commit('SET_USER_INFO', response.data.usuario);
    //         dispatch('GenerateRoutes', response.data.usuario);
    //         if (response.data.usuario.email_verified_at) {
    //           router.push('/dashboard');
    //         } else {
    //           router.push('/email/verify');
    //         }
    //       }).catch((error) => {
    //         router.push(`/singin?error=${error}`);
    //       });
    //   } catch (err) {
    //     console.warn('[vuex.user] LoginByEmail', err);
    //   }
    // },

    // Usada pelo template original para buscar informações do usuário em @/demo/api/mock_user.js
    // GetUserInfo: async ({ state }) => {
    //   console.log('[vuex.user] GetUserInfo');
    //   try {
    //     // Since mockjs does not support custom status codes, it can only be hacked like this
    //     if (!state.token) {
    //       throw new Error('Verification failed, please login again.');
    //     }
    //     // Verify returned roles are a non-null array
    //     if (state.roles && state.roles.length === 0) {
    //       throw new Error('getInfo: roles must be a non-null array!');
    //     }
    //   } catch (err) {
    //     console.warn('[vuex.user] GetUserInfo', err);
    //   }
    // },

    // Usada para Logout antes do Keycloak
    // LogOut: async ({ commit, state }) => {
    //   const tk = state.token;
    //   try {
    //     console.log('[vuex.user] LogOut');
    //     await commit('SET_USER_INFO', { logout: true });
    //   } catch (err) {
    //     console.warn('[vuex.user] LogOut', err);
    //   }
    //   try {
    //     await axios.post(`${process.env.VUE_APP_URL_API}/auth/logout`, {}, {
    //       headers: { Authorization: tk },
    //     });
    //   } catch (err) {
    //     console.warn('[vuex.user] LogOut', err);
    //   }
    // },

    // Dynamically modify permissions
    /* ChangeRoles: async ({ commit, dispatch }, role) => {
      try {
        console.log('[vuex.user] ChangeRoles', role);
        await commit('SET_TOKEN', role);
        const data = await getUserInfo(role);
        await commit('SET_USER_INFO', data);
        // Redraw the side menu after dynamically modifying the permissions
        await dispatch('GenerateRoutes', data);
      } catch (err) {
        console.warn('[vuex.user] ChangeRoles', err);
      }
    }, */
  },
};

export default user;
